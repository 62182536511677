import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SizedBox } from '../../components/base/SizedBox/SizedBox';
import { Spacer } from '../../components/base/Spacer/Spacer';
import { YesCancelDialog } from '../../components/dialogs/YesCancelDialog/YesCancelDialog';
import DashboardLayout from '../../components/layouts/DashboardLayout/DashboardLayout';
import DialogContext from '../../contexts/DialogContext';
import UserContext from '../../contexts/UserContext';
import { ICoach } from '../../typings/ICoach';
import { ICoachingRequest } from '../../typings/ICoachingRequest';
import { Api } from '../../utils/api';
import styles from './CoachesPage.module.css';

interface ICoachesPage {

}

export const CoachesPage = ({ }: ICoachesPage) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, isCoach } = useContext(UserContext);
    const { showError, openDialog } = useContext(DialogContext);
    const [coaches, setCoaches] = useState<ICoach[]>();
    const [coachingRequests, setCoachingRequests] = useState<ICoachingRequest[] | null>(null);

    useEffect(() => {
        if (!user) return;
        let cancelled = false;

        (async () => {
            const coaches = await Api.getMyCoaches(user.id);
            if (cancelled) return;
            setCoaches(coaches);
            const coachingRequests = await Api.getCoachingRequests(user.id);
            if (cancelled) return;
            setCoachingRequests(coachingRequests.filter(req => !req.denied));
        })();

        return () => { cancelled = true; };
    }, [user]);

    const onDeleteCoach = async (coachId: number) => {
        const success = await Api.deleteCoach(user!.id, coachId);
        if (!success) {
            showError(t('error deleting request'));
            return;
        }
        setCoaches(coaches => {
            const newCoaches = coaches!.slice();
            const index = newCoaches.findIndex(c => c.id === coachId);
            if (index !== -1) newCoaches.splice(index, 1);
            return newCoaches;
        })
    }

    const onDeleteCoachWrapper = (e: any, coachId: number) => {
        e.preventDefault();
        e.stopPropagation();
        openDialog(
            <YesCancelDialog
                title={t('are your sure')}
                onYes={() => onDeleteCoach(coachId)}
            />
        );
    }

    const onDeleteRequest = async (req: ICoachingRequest) => {
        const success = await Api.deleteCoachingRequest(req.id);
        if (!success) {
            showError(t('error deleting request'));
            return;
        }
        setCoachingRequests((coachingRequests) => {
            const newCoachingRequests = coachingRequests!.slice();
            const index = newCoachingRequests!.findIndex(c => c.id === req.id);
            if (index !== -1) newCoachingRequests!.splice(index, 1);
            return newCoachingRequests;
        });
    }

    const onAcceptRequest = async (req: ICoachingRequest) => {
        const success = await Api.acceptCoachingRequest(req.id);
        if (!success) {
            showError(t('error deleting request'));
            return;
        }
        setCoachingRequests((coachingRequests) => {
            const newCoachingRequests = coachingRequests!.slice();
            const index = newCoachingRequests!.findIndex(c => c.id === req.id);
            if (index !== -1) newCoachingRequests!.splice(index, 1);
            return newCoachingRequests;
        });
        const coaches = await Api.getMyCoaches(user!.id);
        setCoaches(coaches);
    }

    useEffect(() => {
        if (user === null) return;
        if (isCoach) navigate('/trainees');
    }, [user, isCoach]);

    return (
        <DashboardLayout>
            <SizedBox height={8} />
            <div className={styles.title}>{t('coaches')}</div>
            <div className={styles.listBox}>
                {coaches?.map(coach => (
                    <div className={styles.coach}>
                        <div className={styles.name}>{coach.name}</div>
                        <Spacer flex={1} />
                        <div className={styles.deleteIcon} onClick={(e) => onDeleteCoachWrapper(e, coach.id)}>
                            <Icon size={1.2} path={mdiClose} />
                        </div>
                    </div>
                ))}
            </div>
        </DashboardLayout>
    );
};
