import {SportType} from "./SportType";

export enum TrainingSport {
    K1 = 1,
    K2 = 2,
    K4 = 4,
    C1 = 5,
    C2 = 6,
    C4 = 7,
    SUP = 8,
    DB = 9,
    RSC = 10,
    RDS = 11,
    RQ = 14,
    RP = 12,
    RCF = 13,
    R8 = 15,
}

export const getTrainingSportStringKey = (sport: TrainingSport) => {
    switch (sport) {
        case TrainingSport.K1:
        case TrainingSport.K2:
        case TrainingSport.K4:
            return 'kayak';
        case TrainingSport.C1:
        case TrainingSport.C2:
        case TrainingSport.C4:
            return 'canoe';
        case TrainingSport.SUP:
            return 'sup';
        case TrainingSport.DB:
            return 'dragon boat';
        case TrainingSport.RSC:
        case TrainingSport.RDS:
        case TrainingSport.RQ:
            return 'sculling';
        case TrainingSport.RP:
        case TrainingSport.RCF:
        case TrainingSport.R8:
            return "rowing";
    }
}

export const isRowing = (sport: TrainingSport) => {
    return [
        TrainingSport.RSC,
        TrainingSport.RDS,
        TrainingSport.RQ,
        TrainingSport.RP,
        TrainingSport.RCF,
        TrainingSport.R8,
    ].includes(sport)
}

export const getTrainingSportStringFromNumber = (type: TrainingSport) => {
    switch (type) {
        case TrainingSport.K1:
            return 'K1';
        case TrainingSport.K2:
            return 'K2';
        case TrainingSport.K4:
            return 'K4';
        case TrainingSport.C1:
            return 'C1';
        case TrainingSport.C2:
            return 'C2';
        case TrainingSport.C4:
            return 'C4';
        case TrainingSport.SUP:
            return 'SUP';
        case TrainingSport.DB:
            return 'DB';
        case TrainingSport.RSC:
            return 'RSC';
        case TrainingSport.RDS:
            return 'RDS';
        case TrainingSport.RQ:
            return 'RQ';
        case TrainingSport.RP:
            return 'RP';
        case TrainingSport.RCF:
            return 'RCF';
        case TrainingSport.R8:
            return 'R8';
    }
}

export const trainingSportMapping = (type: TrainingSport): SportType => {
    if ([TrainingSport.K1, TrainingSport.K2, TrainingSport.K4].includes(type)) return SportType.Kayaking;
    else if ([TrainingSport.C1, TrainingSport.C2, TrainingSport.C4].includes(type)) return SportType.Canoeing;
    else if (TrainingSport.SUP) return SportType.Suping;
    else if ([TrainingSport.RSC, TrainingSport.RDS, TrainingSport.RP, TrainingSport.RCF, TrainingSport.RQ, TrainingSport.R8].includes(type)) return SportType.Rowing;
    else if (TrainingSport.DB) return SportType.DragonBoating;
    else {  // TODO
        console.log('Unknown training sport', type);
        return SportType.Sculling;
    }
}

export const isTwoPaddle = (type: TrainingSport): boolean => {
    switch (type) {
        case TrainingSport.K1:
        case TrainingSport.K2:
        case TrainingSport.K4:
        case TrainingSport.RSC:
        case TrainingSport.RDS:
        case TrainingSport.RQ:
            return true;
        default:
            return false
    }
}
