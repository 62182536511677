export enum SeriesType {
    STROKES = "strokes",
    SPEEDS = "speeds",
    TEMPOS = "tempos",
    TEMPO_500S = "tempo500s",
    HEARTRATE = "heartRates",
    GPS = "gpsLocation",
    DISTANCEPERSTROKE = "distancePerStroke",
    IMUS = "imus",

    OTHER = "other",
    PULLING_FORCE = "pulling force",
    DETAILS = "pulling force details",
    HEARTRATEPLOTBANDS = "heartRatePlotBands",

    HEARTRATE_ZONES = "heartRateZones",
    SPEED_ZONES = "speedZones",
    TEMPO_ZONES = "tempoZones",
    TEMPO_500_ZONES = "tempo500Zones",
    FORCE_ZONES = "forceZones",
}
