import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContext from '../../../contexts/DialogContext';
import { Button } from '../../base/Button/Button';
import { SizedBox } from '../../base/SizedBox/SizedBox';
import DialogShim from '../DialogShim';
import styles from "./OkDialog.module.css";

interface IOkDialog {
    message: string;
    title?: string;
    dialogKey: string;
}

const OkDialog = ({ message, title = "Error", dialogKey: key }: IOkDialog) => {
    const dialogContext = useContext(DialogContext);
    const { t } = useTranslation();
    const onDismiss = () => dialogContext.dismissDialog(key);
    if (key === undefined) console.warn('Dialog without a key opened!');

    return (
        <DialogShim
            title={title}
            onClose={onDismiss}
        >
            <div className={styles.okDialogText}>
                {message}
            </div>
            <SizedBox height={16} />
            <div className={styles.buttonContainer}>
                <Button
                    style={{ minWidth: 80 }}
                    label={t('ok')}
                    onClick={onDismiss}
                />
            </div>
        </DialogShim>
    );
}

export default OkDialog;
