import moment from "moment";
import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {Api} from "../utils/api";
import AthleteFilterContext from "./AthleteFilterContext";
import TrainingsFilterContext from "./TrainingsFilterContext";
import UserContext from "./UserContext";
import {IApiTrainingCommonBase} from "../typings/IApiTrainingCommonBase";

interface ITrainingsContext {
    trainingCommons: IApiTrainingCommonBase[];
    loadMore: () => void;
    hasMore: boolean;
    pageIndex: number;
}

const defaultTrainingsContextValues: ITrainingsContext = {
    trainingCommons: [],
    loadMore: () => {
    },
    hasMore: true,
    pageIndex: 0
};

export const TrainingsContext = createContext(defaultTrainingsContextValues);

const pageSize = 30;

export const TrainingsContextProvider = ({children}: { children: ReactNode }) => {
    const {user, isCoach} = useContext(UserContext);
    const {athlete} = useContext(AthleteFilterContext);
    const [trainingCommons, setTrainingCommons] = useState<IApiTrainingCommonBase[]>([]);
    const {startDate, endDate, problemReported, problemResolved,
        deviceQuery} = useContext(TrainingsFilterContext);
    const [hasMore, setHasMore] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);

    const loadMore = useCallback(() => {
        setPageIndex(x => x + 1);
    }, []);

    useEffect(() => {
        setPageIndex(0);
    }, [isCoach, athlete, startDate, endDate]);

    useEffect(() => {
        const from = (startDate && moment(startDate).startOf("day")) ?? null;
        const to = (endDate && moment(endDate).endOf("day")) || moment();
        if (pageIndex === 0)
            setTrainingCommons([]);
        Api.getTrainings(athlete != null ? athlete.id : null, pageSize, pageIndex * pageSize,
            from, to, problemReported, problemResolved, deviceQuery)
            .then(({
                       trainingCommons,
                       hasMore
                   }) => {
                setPageIndex(x => {
                    if (pageIndex === 0) {
                        setHasMore(hasMore);
                        setTrainingCommons(trainingCommons);
                    } else if (x === pageIndex) {
                        setHasMore(hasMore);
                        setTrainingCommons(x => {
                            return [...x, ...trainingCommons];
                        });
                    }
                    return x;
                });
            }).catch(console.log).finally(() => {
        });
    }, [isCoach, user, athlete, startDate, endDate, problemReported, problemResolved, pageIndex, deviceQuery]);

    return (
        <TrainingsContext.Provider value={{
            trainingCommons,
            loadMore,
            hasMore,
            pageIndex,
        }}>
            {children}
        </TrainingsContext.Provider>
    );
};

export default TrainingsContext;
