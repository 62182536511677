import {ChangeEventHandler} from "react";
import {Labelled} from '../Labelled/Labelled';
import styles from "./Textarea.module.css";

interface ITextarea {
    label?: string | null;
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    className?: string;
    classNameExtender?: string;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
}

export const Textarea = ({
    label = null,
    value,
    onChange,
    className,
    classNameExtender,
    placeholder,
    readOnly,
    disabled
}: ITextarea) => {
    return (
        <Labelled label={label}>
            <div className={styles.boxRow}>
                <textarea
                    value={value}
                    className={`${className ?? styles.input} ${classNameExtender ? classNameExtender : ''}`}
                    onChange={onChange}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={disabled}
                />
            </div>
        </Labelled>
    )
}
