import moment from 'moment';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ITrainingCommon} from '../../../typings/ITrainingCommon';
import {getSportIcon} from '../../../typings/SportType';
import {trainingSportMapping} from '../../../typings/TrainingSport';
import {SizedBox} from '../../base/SizedBox/SizedBox';
import {Spacer} from '../../base/Spacer/Spacer';
import {TextInput} from '../../base/TextInput/TextInput';
import {StatsGlanceSmall} from '../GlanceStatsSmall/GlanceStatsSmall';
import styles from './AnalyticsHeader.module.css';
import {avg, max} from "../../../utils/arrayUtils";
import {Textarea} from "../../base/Textarea/Textarea";
import {Button} from "../../base/Button/Button";
import {Api} from "../../../utils/api";

interface IAnalyticsHeader {
    trainingData: ITrainingCommon;
    onChanged: () => void;
}

export const AnalyticsHeader = ({trainingData, onChanged}: IAnalyticsHeader) => {
    const {t} = useTranslation();
    const [notes, setNotes] = useState<string | undefined>();

    useEffect(() => {
        setNotes(trainingData.notes);
    }, [trainingData.notes]);

    const allForces = useMemo(() => {
            return (trainingData.trainings.map(x =>
                [x.detailedLeftPaddlingForceNSeries, x.detailedRightPaddlingForceNSeries])).flat()
                .filter(x => x != null)
                .map(x => x!)
                .flat();
        },
        [trainingData.trainings]);

    const avgMax = useMemo(() => {
            const avgSumMaxByForceArray = allForces.reduce((res, force) => {
                res.sum += force;
                if (res.max < force)
                    res.max = force;
                return res;
            }, {sum: 0, max: -Infinity});
            return {avg: avgSumMaxByForceArray.sum / allForces.length, max: avgSumMaxByForceArray.max};
        }
        , [allForces]);

    const resolveProblem = () => {
        Api.updateTrainingCommon(trainingData.id, {reportedProblemResolved: true}).finally(onChanged)
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img className={styles.sportIcon} src={getSportIcon(trainingSportMapping(trainingData.sport))}/>
                <SizedBox width={4}/>
                <div className={styles.headerCell}>
                    <div className={styles.trainingName}>{trainingData.name}</div>
                    {trainingData.trainings.length === 1 && <div>
                        {trainingData.trainings[0].user?.name ?? t("guest")}
                    </div>}
                </div>
                <Spacer flex={1}/>
                <div className={styles.headerTime}>
                    {moment(trainingData.startAt).format('YYYY MMMM D HH:mm')}
                </div>
            </div>
            <StatsGlanceSmall
                avgStrokeRateSpm={trainingData.avgStrokeRateSpm}
                maxStrokeRateSpm={trainingData.maxStrokeRateSpm}
                lengthKm={trainingData.lengthKm}
                startAt={trainingData.startAt}
                endAt={trainingData.endAt}
                avgSpeedKph={trainingData.avgSpeedKph}
                maxSpeedKph={trainingData.maxSpeedKph}
                avgPaddlingForceN={avgMax.avg}
                maxPaddlingForceN={avgMax.max}
                avgHeartRateBpm={avg(trainingData.trainings
                    .filter(x => x.avgHeartRateBpm != null)
                    .map(x => x.avgHeartRateBpm as number))}
                maxHeartRateBpm={max(trainingData.trainings
                    .filter(x => x.maxHeartRateBpm != null)
                    .map(x => x.maxHeartRateBpm as number))}
                sport={trainingData.sport}
            />
            {notes && notes !== "" &&
                <>
                    <TextInput
                        label={t('notes')}
                        value={notes || ""}
                        onChange={e => {
                        }}
                        readOnly={true}
                    />
                    <SizedBox height={12}/>
                </>
            }
            {trainingData.reportedProblem && trainingData.reportedProblem !== "" &&
                <>
                    <Textarea
                        label={t('reported problem')}
                        value={trainingData.reportedProblem || ""}
                        onChange={e => {
                        }}
                        readOnly={true}
                    />
                    <SizedBox height={12}/>
                    <Button label={trainingData.reportedProblemResolved ? "Resolved" : "Resolve"}
                            disabled={!!trainingData.reportedProblemResolved}
                            onClick={resolveProblem}
                            style={{alignSelf: "flex-start"}}></Button>
                </>
            }
        </div>
    );
};
