import React, {
    createContext,
    MutableRefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {ICoachingRequest} from "../typings/ICoachingRequest";
import {IUser} from "../typings/IUser";
import {LoginState} from "../typings/LoginState";
import {UserType} from "../typings/UserType";
import {Api} from "../utils/api";
import DialogContext from "./DialogContext";
import jwt_decode from "jwt-decode";
import {IToken} from "../typings/IToken";
import moment from "moment/moment";

interface IUserContext {
    loginState: LoginState;
    user: IUser | null;
    userId: number | null;
    isActuallyCoach: boolean | undefined;
    isCoach: boolean | undefined; // undefined=loading

    login: (email: string, password: string, rememberMe: boolean) => Promise<void>;
    refreshToken: (() => Promise<IToken>) | null;
    isAccessTokenExpired: () => Boolean;
    accessTokenRef: MutableRefObject<string|undefined>;
    reloadUser: () => Promise<void>;
    resetPassword: (password: string, encodedHash: string) => Promise<number>;
    resetPasswordRequest: (email: string) => Promise<number>;
    logout: () => Promise<void>;

    setIsCoachVirtual: (isCoach: boolean) => void;
    coachSwitch: number;

    incomingCoachingRequests: ICoachingRequest[] | null;
    acceptCoachingRequest: (req: ICoachingRequest) => Promise<void>;
    deleteCoachingRequest: (req: ICoachingRequest) => Promise<void>;
}

const defaultContext: IUserContext = {
    loginState: LoginState.loading,
    user: null,
    userId: null,
    isActuallyCoach: undefined,
    isCoach: undefined,

    login: async () => {
    },
    isAccessTokenExpired: () => false,
    accessTokenRef: {current: undefined},
    refreshToken: null,
    reloadUser: async () => {
    },
    resetPassword: async () => 400,
    resetPasswordRequest: async () => 400,
    logout: async () => {
    },

    setIsCoachVirtual: () => {
    },
    coachSwitch: 0,

    incomingCoachingRequests: null,
    acceptCoachingRequest: async () => {
    },
    deleteCoachingRequest: async () => {
    },
};

const UserContext = createContext(defaultContext);

export const UserContextProvider = ({children}: { children: React.ReactNode }) => {
    const {t, i18n} = useTranslation();
    const {showError} = useContext(DialogContext);
    const [loginState, setLoginState] = useState(LoginState.loading);
    const [user, setUser] = useState<IUser | null>(null);
    const [isCoachVirtual, setIsCoachVirtual] =
        useState<boolean|undefined>(undefined);
    const [incomingCoachingRequests, setIncomingCoachingRequests] =
        useState<ICoachingRequest[] | null>(null);
    const [coachSwitch, setCoachSwitch] = useState<number>(0);
    const [tokens, setTokens] = useState<IToken>();
    const refreshTokenPromise = useRef<Promise<IToken>>();
    const accessTokenRef = useRef<string>();

    const userId = useMemo(() => {
        if (!tokens?.access_token) return null;
        const decoded = jwt_decode(tokens?.access_token) as any;
        return +decoded.sub;
    }, [tokens]);

    const isAccessTokenExpired = useCallback(() => {
        if (!tokens?.access_token) return false;
        const decoded = jwt_decode(tokens?.access_token) as any;
        const exp = moment.unix(decoded.exp);
        return moment() >= exp;
    }, [tokens]);

    useEffect(() => {
        const tokenJson = localStorage.getItem("token");
        const userJson = localStorage.getItem("user");
        if (tokenJson !== null && userJson !== null) {
            try {
                const user = JSON.parse(userJson);
                const tokens = JSON.parse(tokenJson);
                if (!user || !tokens) throw new Error("local user or token invalid");
                setTokens(tokens);
                accessTokenRef.current = tokens.access_token;
                setUser(user);
                setLoginState(LoginState.loggedin);
            } catch (err) {
                setLoginState(LoginState.loggedout);
                setTokens(undefined);
                accessTokenRef.current = undefined;
                localStorage.removeItem("token");
                localStorage.removeItem("user");
            }
        } else {
            setLoginState(LoginState.loggedout);
        }
    }, []);

    const login = async (email: string, password: string, rememberMe: boolean) => {
        try {
            const res = await Api.loginPassword(email, password);
            setTokens(res);
            accessTokenRef.current = res.access_token;
            localStorage.setItem("token", JSON.stringify(res));
            setLoginState(LoginState.loggedin);
        } catch (e) {
            setLoginState(LoginState.loggedout);
            setTokens(undefined);
            accessTokenRef.current = undefined;
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            throw e;
        }
    };

    useEffect(() => {
        if (userId === null) return
        const load = async () => {
            const coachingRequests = await Api.getCoachingRequests(userId);
            setIncomingCoachingRequests(coachingRequests.filter(req => !req.denied && (req.athleteId === userId)));
        }
        load().catch(console.log);
    }, [userId]);

    const reloadUser = useCallback(async () => {
        if (userId === null) return
        const updatedUser = await Api.getUser(userId.toString());
        setUser(updatedUser);
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setIsCoachVirtual((updatedUser as IUser).userType === UserType.Coach);
    }, [userId]);

    useEffect(() => {
        reloadUser().catch(console.log);
    }, [reloadUser]);

    const resetPassword = async (password: string, encodedHash: string) => {
        try {
            const statusCode = await Api.resetPassword(password, encodedHash);
            return statusCode;
        } catch (e) {
            return 400;
        }
    };

    const resetPasswordRequest = async (email: string) => {
        try {
            const statusCode = await Api.resetPasswordRequest(email);
            return statusCode;
        } catch (e) {
            return 400;
        }
    };

    const logout = async () => {
        setLoginState(LoginState.loggedout);
        setUser(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setTokens(undefined);
        accessTokenRef.current = undefined;
    };

    const acceptCoachingRequest = async (req: ICoachingRequest) => {
        const success = await Api.acceptCoachingRequest(req.id);
        if (!success) {
            showError(t("error deleting request"));
            return;
        }
        setIncomingCoachingRequests((coachingRequests) => {
            const newCoachingRequests = coachingRequests!.slice();
            const index = newCoachingRequests!.findIndex(c => c.id === req.id);
            if (index !== -1) newCoachingRequests!.splice(index, 1);
            return newCoachingRequests;
        });
    };

    const deleteCoachingRequest = async (req: ICoachingRequest) => {
        const success = await Api.deleteCoachingRequest(req.id);
        if (!success) {
            showError(t("error deleting request"));
            return;
        }
        setIncomingCoachingRequests((coachingRequests) => {
            const newCoachingRequests = coachingRequests!.slice();
            const index = newCoachingRequests!.findIndex(c => c.id === req.id);
            if (index !== -1) newCoachingRequests!.splice(index, 1);
            return newCoachingRequests;
        });
    };

    const setIsCoachVirtualWrapper = useCallback((newVal: boolean) => {
        setIsCoachVirtual(newVal);
        setCoachSwitch((val) => val + 1);
    }, []);

    const refreshToken = useCallback(() => {
        if (!refreshTokenPromise.current) {
            refreshTokenPromise.current = (async () => {
                try {
                    const existingToken = tokens?.refresh_token!!;
                    const res = await Api.refresh(existingToken);
                    setTokens(res);
                    accessTokenRef.current = res.access_token;
                    localStorage.setItem("token", JSON.stringify(res));
                    return res;
                } finally {
                    refreshTokenPromise.current = undefined
                }
            })();
        }
        return refreshTokenPromise.current;
    }, [tokens]);

    return (
        <UserContext.Provider value={{
            loginState,
            user,
            userId,
            isCoach: isCoachVirtual,
            isActuallyCoach: user == null ? undefined : user.userType === UserType.Coach,

            login,
            reloadUser,
            refreshToken,
            isAccessTokenExpired,
            accessTokenRef,
            resetPassword,
            resetPasswordRequest,
            logout,

            setIsCoachVirtual: setIsCoachVirtualWrapper,
            coachSwitch,

            incomingCoachingRequests,
            acceptCoachingRequest,
            deleteCoachingRequest,
        }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
