import {useMemo} from "react";
import {avg, max} from "../../../utils/arrayUtils";
import {ITrainingCommonTraining} from "../../../typings/ITrainingCommonTraining";
import {ITrainingCommon} from "../../../typings/ITrainingCommon";

export const useAnalyticsPanelSelection = (selection: [number, number],
                                           forceSelection: [number, number] | undefined,
                                           sliceMax: number,
                                           seriesLength: number,
                                           selectedTrainings: ITrainingCommonTraining[],
                                           trainingCommon: ITrainingCommon,
                                           isTracker: boolean) => {
    const sliceStartIndex = useMemo(() =>
        Math.floor(selection[0] / 1000), [selection]);
    const sliceEndIndex = useMemo(() => {
        if (selection[1] >= sliceMax)
            return seriesLength - 1;
        else
            return Math.ceil(selection[1] / 1000);
    }, [selection, seriesLength, sliceMax]);
    const speedStartIndex = useMemo(() =>
        isTracker ? Math.floor(selection[0] / 100) : sliceStartIndex,
        [selection, isTracker, sliceStartIndex]);
    const speedEndIndex = useMemo(() => {
        if(!isTracker) return sliceEndIndex;
        if (selection[1] >= sliceMax)
            return (seriesLength - 1)*10;
        else
            return Math.ceil(selection[1] / 100);
    }, [selection, seriesLength, sliceMax, isTracker, sliceEndIndex]);

    const allForcesSlice = useMemo(() => {
            const s = selection;
            const startIndex = Math.floor(s[0] / 1000);
            let endIndex: number;
            if (s[1] >= sliceMax)
                endIndex = seriesLength - 1;
            else
                endIndex = Math.ceil(s[1] / 1000);
            return (selectedTrainings.map(x =>
                [x.leftPaddlingForceNSeries, x.rightPaddlingForceNSeries])).flat()
                .filter(x => x != null)
                .map(x => x!.slice(startIndex, endIndex + 1))
                .flat();
        },
        [selection, sliceMax, seriesLength, selectedTrainings]);

    const allDetailedForcesSlice = useMemo(() => {
            const s = forceSelection || selection;
            const startIndex = Math.floor(s[0] / 10);
            let endIndex: number;
            if (s[1] >= sliceMax)
                endIndex = (seriesLength - 1) * 100;
            else
                endIndex = Math.ceil(s[1] / 10);
            return (selectedTrainings.map(x =>
                [x.detailedLeftPaddlingForceNSeries, x.detailedRightPaddlingForceNSeries])).flat()
                .filter(x => x != null)
                .map(x => x!.slice(startIndex, endIndex + 1))
                .flat();
        },
        [forceSelection, selection, sliceMax, seriesLength, selectedTrainings]);
    const speedSlice = useMemo(() =>
            trainingCommon.speedData?.slice(speedStartIndex, speedEndIndex + 1) ?? [],
        [speedStartIndex, speedEndIndex, trainingCommon.speedData]);
    const tempoSlice = useMemo(() =>
            trainingCommon.tempoData?.slice(speedStartIndex, speedEndIndex + 1) ?? [],
        [speedStartIndex, speedEndIndex, trainingCommon.tempoData]);
    const tempo500Slice = useMemo(() =>
            trainingCommon.tempo500Data?.slice(speedStartIndex, sliceEndIndex + 1) ?? [],
        [sliceEndIndex, speedStartIndex, trainingCommon.tempo500Data]);
    const strokeSlice = useMemo(() =>
            trainingCommon.strokeData?.slice(sliceStartIndex, sliceEndIndex + 1) ?? [],
        [sliceEndIndex, sliceStartIndex, trainingCommon.strokeData]);
    const hrSlice = useMemo(() =>
            selectedTrainings.map(x =>
                x.heartRateBpmSeries?.slice(sliceStartIndex, sliceEndIndex + 1) ?? []),
        [selectedTrainings, sliceEndIndex, sliceStartIndex]);

    const sliceForceAvg = useMemo(() => allDetailedForcesSlice.length > 0 ? avg(allDetailedForcesSlice) : 0,
        [allDetailedForcesSlice]);
    const sliceForceMax = useMemo(() => allDetailedForcesSlice.length > 0 ? max(allDetailedForcesSlice) : 0,
        [allDetailedForcesSlice]);
    const sliceStrokeAvg = useMemo(() => strokeSlice.length > 0 ? avg(strokeSlice) : 0, [strokeSlice]);
    const sliceStrokeMax = useMemo(() => strokeSlice.length > 0 ? max(strokeSlice) : 0, [strokeSlice]);
    const sliceDistance = useMemo(() => trainingCommon.distanceData ? trainingCommon.distanceData[speedEndIndex] -
        trainingCommon.distanceData[speedStartIndex] : 0, [speedStartIndex, speedEndIndex, trainingCommon.distanceData]);
    const sliceSpeedAvg = useMemo(() => speedSlice.length > 0 ? avg(speedSlice) : 0, [speedSlice]);
    const sliceSpeedMax = useMemo(() => speedSlice.length > 0 ? max(speedSlice) : 0, [speedSlice]);
    const sliceTempo500Avg = useMemo(() => tempo500Slice.length > 0 ? avg(tempo500Slice) : 0, [tempo500Slice]);
    const sliceTempo500Max = useMemo(() => tempo500Slice.length > 0 ? max(tempo500Slice) : 0, [tempo500Slice]);
    const sliceHeartRateAvg = useMemo(() => hrSlice.length > 0 ?
        avg(hrSlice.flat()) : 0, [hrSlice]);
    const sliceHeartRateMax = useMemo(() => hrSlice.length > 0 ?
        max(hrSlice.flat()) : 0, [hrSlice]);

    return {
        hrSlice,
        allForcesSlice,
        speedSlice,
        tempoSlice,
        tempo500Slice,
        sliceStartIndex,
        sliceEndIndex,
        sliceForceAvg,
        sliceForceMax,
        sliceHeartRateAvg,
        sliceHeartRateMax,
        sliceStrokeAvg,
        sliceStrokeMax,
        sliceSpeedAvg,
        sliceSpeedMax,
        sliceTempo500Avg,
        sliceTempo500Max,
        sliceDistance
    }
}