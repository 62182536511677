import {SettingsDropdown} from "../SettingsDropdown/SettingsDropdown";
import styles from "./DashboardHeader.module.scss";
import {Nav, Navbar, NavbarToggler, NavItem, Offcanvas, OffcanvasBody} from "reactstrap";
import {Link} from "react-router-dom";
import {mdiAccessPoint, mdiAccountMultiple, mdiHome, mdiListBox} from "@mdi/js";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {NavButton} from "../../../base/NavButton/NavButton";

export const DashboardHeader = () => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const navBar = <>
        <Nav className={styles.nav} navbar>
            <NavItem>
                <NavButton
                    icon={mdiHome}
                    title={t('dashboard')}
                    link={'/dashboard'}
                />
            </NavItem>
            <NavItem>
                <NavButton
                    icon={mdiListBox}
                    title={t('training list')}
                    link={'/trainings'}
                />
            </NavItem>
            <NavItem>
                <NavButton
                    icon={mdiAccountMultiple}
                    title={"Users"}
                    link={'/users'}
                />
            </NavItem>
            <NavItem>
                <NavButton
                    icon={mdiAccessPoint}
                    title={t('live')}
                    link={'/live'}
                />
            </NavItem>
        </Nav>
        <div className="d-none d-xl-block"><SettingsDropdown/></div>
    </>;
    return (
        <Navbar className={styles.container} expand={"xl"} dark={true}>
            <Link to="/" className={`navbar-brand ${styles.brand}`}>
                <img className={styles.logo} alt="" src="/icons/logos/paddlemate_logo_feher.png"/>
                <div style={{marginLeft: 20, fontWeight: 700, fontSize: 26, display: "inline"}}>ADMIN</div>
            </Link>
            <div className={`d-flex d-xl-none`} style={{flex: 1}}></div>
            <NavbarToggler onClick={toggle}/>
            <div className={styles.defaultNavbar}>{navBar}</div>
            <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" className={styles.openCollapse}>
                <button
                    type="button"
                    onClick={toggle}
                    className={`btn-close btn-close-white ${styles.close}`}
                />
                <OffcanvasBody className={styles.canvasBody}>
                    {navBar}
                </OffcanvasBody>
            </Offcanvas>
        </Navbar>
    )
}
