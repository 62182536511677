import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Formatter } from '../../../utils/formatter';
import { GlanceIndicatorSmall } from '../GlanceIndicatorSmall/GlanceIndicator';
import styles from './GlanceStatsSmall.module.css';
import {isRowing, TrainingSport} from "../../../typings/TrainingSport";
import {speedToTempo500} from "../../../utils/speedToTempo500";
import Highcharts from "highcharts";

interface IStatsGlanceSmall {
    avgPaddlingForceN?: number;
    maxPaddlingForceN?: number;
    avgStrokeRateSpm?: number;
    maxStrokeRateSpm?: number;
    lengthKm?: number;
    startAt?: Date;
    endAt?: Date;
    avgSpeedKph?: number;
    maxSpeedKph?: number;
    avgHeartRateBpm?: number;
    maxHeartRateBpm?: number;
    sport: TrainingSport
}

export enum SmallStatCategory {
    Force,
    StrokeRate,
    Distance,
    Duration,
    Speed,
    HeartRate,
}

export const getStatIcon = (cat: SmallStatCategory) => {
    switch (cat) {
        case SmallStatCategory.Force:
            return '/icons/ic_paddlingforce.png';
        case SmallStatCategory.StrokeRate:
            return '/icons/ic_strokes.png';
        case SmallStatCategory.Distance:
            return '/icons/ic_distance.png';
        case SmallStatCategory.Duration:
            return '/icons/ic_time.png';
        case SmallStatCategory.Speed:
            return '/icons/ic_speed.png';
        case SmallStatCategory.HeartRate:
            return '/icons/ic_heartrate.png';
    }
}

export const StatsGlanceSmall = ({
    avgPaddlingForceN,
    maxPaddlingForceN,
    avgStrokeRateSpm,
    maxStrokeRateSpm,
    lengthKm,
    startAt,
    endAt,
    avgSpeedKph,
    maxSpeedKph,
    avgHeartRateBpm,
    maxHeartRateBpm,
    sport,
}: IStatsGlanceSmall) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.Force)}
                text={t('force')}
                value={!avgPaddlingForceN ? '0 N' : `${avgPaddlingForceN.toFixed(1)} N`}
                smallValue={!maxPaddlingForceN ? '' : `${t('max')} ${maxPaddlingForceN.toFixed(1)} N`}
            />
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.StrokeRate)}
                text={t('stroke rate')}
                value={!avgStrokeRateSpm ? '0 / min' : `${avgStrokeRateSpm.toFixed(1)} / min`}
                smallValue={!maxStrokeRateSpm ? '' : `${t('max')} ${maxStrokeRateSpm.toFixed(1)} / min`}
            />
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.Distance)}
                text={t('distance')}
                value={`${lengthKm === undefined ? '' : lengthKm.toFixed(1)} km`}
            />
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.Duration)}
                text={t('duration')}
                value={(startAt || endAt) === undefined ? '' : Formatter.secToTimeString(moment(endAt).diff(moment(startAt), 'seconds'))}
            />
            {isRowing(sport) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.Speed)}
                    text={t('tempo split')}
                    value={avgSpeedKph === undefined ? '' : `${Highcharts.dateFormat("%M:%S", speedToTempo500(avgSpeedKph)*60*1000)} /500m`}
                    smallValue={maxSpeedKph === undefined ? '' : `${t('max')} ${speedToTempo500(maxSpeedKph).toFixed(1)} /500m`}
                />}
            {!isRowing(sport) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.Speed)}
                    text={t('speed')}
                    value={avgSpeedKph === undefined ? '' : `${avgSpeedKph.toFixed(1)} km/h`}
                    smallValue={maxSpeedKph === undefined ? '' : `${t('max')} ${maxSpeedKph.toFixed(1)} km/h`}
                />}
            {(avgHeartRateBpm === undefined || isNaN(avgHeartRateBpm) || avgHeartRateBpm === 0) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.HeartRate)}
                    text={t('heartrate')}
                    value={''}
                    unavailable={true}
                />
            }
            {!(avgHeartRateBpm === undefined || isNaN(avgHeartRateBpm) || avgHeartRateBpm === 0) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.HeartRate)}
                    text={t('heartrate')}
                    value={avgHeartRateBpm === undefined ? '' : `${avgHeartRateBpm.toFixed(1)} bpm`}
                    smallValue={maxHeartRateBpm === undefined ? '' : `${t('max')} ${maxHeartRateBpm.toFixed(1)} bpm`}
                />
            }
        </div>
    );
};
