import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { SizedBox } from '../SizedBox/SizedBox';
import styles from './DropdownLabel.module.css';

interface IDropdownLabel {
    label: string | JSX.Element;
    open: boolean;
    disabled?: boolean;
}

export const DropdownLabel = ({ label, open, disabled }: IDropdownLabel) => {
    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : ""}`}>
            <div style={{flexShrink: 0}}>{label}</div>
            <SizedBox width={4} />
            <Icon style={{flexShrink: 0}} path={open ? mdiChevronUp : mdiChevronDown} size={1} />
        </div>
    );
};
