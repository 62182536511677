import {TrainingSport} from "./TrainingSport";
import {IApiTrainingCommonTraining} from "./IApiTrainingWithCommonTraining";
import {ICoach} from "./ICoach";

export interface ILatLng {
    lat: number;
    lng: number;
}

export enum TrainingSource {
    Phone = 1,
    Tracker = 2,
    External = 3,
}

export interface IApiTrainingCommonBase {
    id: number;
    name: string;
    sport: TrainingSport;
    lengthKm: number;
    startAt: string;
    endAt: string;
    trainings: IApiTrainingCommonTraining[]
    avgStrokeRateSpm?: number;
    createdBy: ICoach;
    reportedProblem: string | null;
    reportedProblemResolved: boolean;
    source: TrainingSource;
}
