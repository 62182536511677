import moment from 'moment';
import React, {useCallback, useState} from 'react';
import {GroupByPeriod} from '../typings/GroupByPeriod';
import {TimeGrouping} from '../typings/TimeGrouping';
import {TimeInterval} from '../typings/TimeInterval';

interface IStatisticsContext {
    timeInterval: TimeInterval;
    date: Date;
    startDate: Date|null;
    endDate: Date|null;
    timeGrouping: TimeGrouping;
    granularity: GroupByPeriod;

    setTimeInterval: (interval: TimeInterval) => void;
    setDate: (date: Date) => void;
    setGranularity: (granularity: GroupByPeriod) => void;

    reset: () => void;
}

const takeSmallerDate = (date1: Date, date2: Date) => {
    if (moment(date1).isBefore(moment(date2))) return date1;
    return date2;
}

const initialTime = new Date();
const dv: IStatisticsContext = {
    timeInterval: TimeInterval.Week,
    date: initialTime,
    startDate: null,
    endDate: null,
    timeGrouping: TimeGrouping.DayOfWeek,
    granularity: GroupByPeriod.Days,

    setTimeInterval: () => {
    },
    setDate: () => {
    },
    setGranularity: () => {
    },
    reset: () => {
    },
};

export const StatisticsContext = React.createContext(dv);

export const StatisticsContextProvider = ({children}: { children: React.ReactNode }) => {
    const [timeInterval, setTimeInterval] = useState<TimeInterval>(TimeInterval.Week);
    const [date, setDate] = useState<Date>(initialTime);
    const [startDate, setStartDate] = useState<Date|null>(moment(initialTime).startOf('isoWeek').toDate());
    const [endDate, setEndDate] = useState<Date|null>(moment().toDate());
    const [timeGrouping, setTimeGrouping] = useState<TimeGrouping>(TimeGrouping.DayOfWeek);
    const [granularity, setGranularity] = useState<GroupByPeriod>(GroupByPeriod.Days);

    const setTimeIntervalWrapper = useCallback((newInterval: TimeInterval) => {
        setTimeInterval(newInterval);
        const startDateMoment = moment(date).startOf(newInterval === TimeInterval.Week ? 'isoWeek' : newInterval);
        setStartDate(startDateMoment.toDate());
        switch (newInterval) {
            case TimeInterval.Week:
                setTimeGrouping(TimeGrouping.DayOfWeek);
                setGranularity(GroupByPeriod.Days);
                break;
            case TimeInterval.Month:
                setTimeGrouping(TimeGrouping.WeekOfMonth);
                setGranularity(GroupByPeriod.Weeks);
                break;
            case TimeInterval.Year:
                setTimeGrouping(TimeGrouping.Month);
                setGranularity(GroupByPeriod.Months);
                break;
        }
        const intervalEnd = moment(startDateMoment).endOf(newInterval === TimeInterval.Week ? 'isoWeek' : newInterval).toDate();
        setEndDate(takeSmallerDate(initialTime, intervalEnd));
    }, [date]);

    const setDateWrapper = useCallback((newDate: Date) => {
        if (moment(newDate).isSameOrAfter(moment())) return;
        setDate(newDate);
        setStartDate(moment(newDate).startOf(timeInterval === TimeInterval.Week ? 'isoWeek' : timeInterval).toDate());
        let intervalEnd = moment(newDate).endOf(timeInterval === TimeInterval.Week ? 'isoWeek' : timeInterval).toDate();
        if (moment(intervalEnd).isAfter(moment())) intervalEnd = moment().toDate();
        setEndDate(takeSmallerDate(initialTime, intervalEnd));
    }, [timeInterval]);

    const setGranularityWrapper = useCallback((granularity: GroupByPeriod) => {
        switch (timeInterval) {
            case TimeInterval.Week:  // Only 1 valid option for week timeInterval
                setGranularity(GroupByPeriod.Days);
                setTimeGrouping(TimeGrouping.DayOfWeek);
                break;
            case TimeInterval.Month:
                if (granularity === GroupByPeriod.Months) {  // Monthly not available
                    setGranularity(GroupByPeriod.Weeks);     // set to defaults
                    setTimeGrouping(TimeGrouping.WeekOfMonth);
                } else if (granularity === GroupByPeriod.Weeks) {
                    setGranularity(GroupByPeriod.Weeks);
                    setTimeGrouping(TimeGrouping.WeekOfMonth);
                } else {
                    setGranularity(GroupByPeriod.Days);
                    setTimeGrouping(TimeGrouping.DayOfMonth);
                }
                break;
            case TimeInterval.Year:
                if (granularity === GroupByPeriod.Days) {
                    setGranularity(GroupByPeriod.Days);
                    setTimeGrouping(TimeGrouping.DayOfYear);
                } else if (granularity === GroupByPeriod.Weeks) {
                    setGranularity(GroupByPeriod.Weeks);
                    setTimeGrouping(TimeGrouping.WeekOfYear);
                } else {
                    setGranularity(GroupByPeriod.Months);
                    setTimeGrouping(TimeGrouping.Month);
                }
                break;
        }
        setGranularity(granularity);
    }, [timeInterval]);

    const reset = useCallback(() => {
        setTimeInterval(TimeInterval.Week);
        setDate(initialTime);
        setStartDate(null);
        setEndDate(null);
        setTimeGrouping(TimeGrouping.DayOfWeek);
        setGranularity(GroupByPeriod.Days);
    }, []);

    return (
        <StatisticsContext.Provider value={{
            timeInterval,
            date,
            startDate,
            endDate,
            timeGrouping,
            granularity,

            setTimeInterval: setTimeIntervalWrapper,
            setDate: setDateWrapper,
            setGranularity: setGranularityWrapper,

            reset,
        }}>
            {children}
        </StatisticsContext.Provider>
    );
}

export default StatisticsContext;
