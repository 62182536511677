import { ReactNode } from 'react';
import { Spacer } from '../../base/Spacer/Spacer';
import { Footer } from '../../paddlemate/Footer/Footer';
import styles from './CardLayout.module.css';

interface ICardLayout {
    title: string;
    subtitle: string;
    children?: ReactNode;
    middle?: ReactNode;
    footer?: ReactNode;
}

export const CardLayout = ({ title, subtitle, children, middle, footer }: ICardLayout) => {
    return (
        <div className={styles.container}>
            <Spacer flex={2} />
            <div className={styles.card}>
                <div className={styles.header}>
                    <img className={styles.banner} alt="" src="/icons/logos/paddlemate_logo_feher.png" />
                    <div className={styles.admin}>ADMIN</div>
                </div>
                <div className={styles.body}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subtitle}>{subtitle}</div>
                    {children}
                </div>
            </div>
            <div className={styles.middle}>
                {middle}
            </div>
            <Footer />
        </div>
    );
};
