import React, {useState} from 'react';
import {IUser} from "../typings/IUser";

interface IAthleteFilterContext {
    athlete: IUser | undefined | null;  // undefined - loading; null - all
    setAthlete: (athlete: IUser | undefined | null) => void;
}

const defaultAthleteFilterContextValues: IAthleteFilterContext = {
    athlete: undefined,
    setAthlete: () => { },
};

export const AthleteFilterContext = React.createContext(defaultAthleteFilterContextValues);

export const AthleteFilterContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [athlete, setAthlete] = useState<IUser | undefined | null>(undefined);
    return (
        <AthleteFilterContext.Provider value={{
            athlete,
            setAthlete,
        }}>
            {children}
        </AthleteFilterContext.Provider>
    );
}

export default AthleteFilterContext;
