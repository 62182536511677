import {CSSProperties} from "react";
import styles from "./Button.module.css";
import {Spinner} from "../Spinner/Spinner";

interface IButton {
    label: string;
    secondary?: boolean;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button = ({
                           label,
                           className,
                           style,
                           onClick,
                           secondary = false,
                           disabled = false,
                           loading = false
                       }: IButton) => {
    return (
        <button
            className={`${styles.primary} ${secondary ? styles.secondary : ''} ${className ?? ''} ${(disabled || loading) ? styles.disabled : ''} ${styles.base}`}
            style={style}
            onClick={(!disabled && !loading) ? onClick : undefined}
        >
            {!loading && label}
            {loading && <Spinner size={16} cssOverride={{marginLeft: "4px"}}/>}
        </button>
    )
}
