import moment from 'moment';
import { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/base/Button/Button';
import { DateSelector } from '../../components/base/DateTimeSelector/DateSelector';
import { Labelled } from '../../components/base/Labelled/Labelled';
import { Selector } from '../../components/base/Selector/Selector';
import { SizedBox } from '../../components/base/SizedBox/SizedBox';
import { Spacer } from '../../components/base/Spacer/Spacer';
import { TextInput } from '../../components/base/TextInput/TextInput';
import { YesCancelDialog } from '../../components/dialogs/YesCancelDialog/YesCancelDialog';
import DashboardLayout from '../../components/layouts/DashboardLayout/DashboardLayout';
import DialogContext from '../../contexts/DialogContext';
import UserContext from '../../contexts/UserContext';
import { GenderType } from '../../typings/GenderType';
import { SportType } from '../../typings/SportType';
import { Api } from '../../utils/api';
import styles from './ProfilePage.module.css';

const minYear = moment().year() - 100;
const maxYear = moment().year() - 10;

const weights = Array.from({ length: 144 }, (_, i) => i + 41);
const heights = Array.from({ length: 240 }, (_, i) => i + 61);
const bpms = Array.from({ length: 121 }, (_, i) => i + 100);

export const ProfilePage = () => {
    const { t } = useTranslation();
    const { user, logout, reloadUser, incomingCoachingRequests: coachingRequests, acceptCoachingRequest, deleteCoachingRequest } = useContext(UserContext);
    const { showError, openDialog } = useContext(DialogContext);

    const [gender, setGenderRaw] = useState<GenderType>(user!.gender);
    const [birthDate, setBirthDateRaw] = useState<Date>(new Date(user!.birthDate));
    const [height, setHeightRaw] = useState<number>(user!.heightCm);
    const [weight, setWeightRaw] = useState<number>(user!.weightKg);
    const [sport, setSportRaw] = useState<SportType>(user!.sport);
    const [club, setClubRaw] = useState<string>(user!.club);
    const [maxHrBpm, setMaxHrBpmRaw] = useState<number>(user!.maxHrBpm);

    const [changedProfilePic, setChangedProfilePic] = useState<File | null>(null);
    const profilePictureRef = useRef<HTMLImageElement>(null);

    const setGender = useCallback((d: GenderType) => { setGenderRaw(d); setChanged(true); }, []);
    const setBirthDate = useCallback((d: Date) => { setBirthDateRaw(d); setChanged(true); }, []);
    const setHeight = useCallback((d: number) => { setHeightRaw(d); setChanged(true); }, []);
    const setWeight = useCallback((d: number) => { setWeightRaw(d); setChanged(true); }, []);
    const setSport = useCallback((d: SportType) => { setSportRaw(d); setChanged(true); }, []);
    const setClub = useCallback((d: string) => { setClubRaw(d); setChanged(true); }, []);
    const setMaxHrBpm = useCallback((d: number) => { setMaxHrBpmRaw(d); setChanged(true); }, []);

    const [changed, setChanged] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const setFile = (e: any) => {
        if (e.target.files.length !== 1) showError(t('single image upload limit'));
        setChangedProfilePic(e.target.files[0]);
        setChanged(true);
        if (profilePictureRef.current) {
            profilePictureRef.current.src = URL.createObjectURL(e.target.files[0]);
        }
    }

    const onSave = async () => {
        let success = false;
        try {
            setChanged(false);
            setSaving(true);
            await Api.updateUserRequest(user!.id, {
                birthdate: birthDate,
                sport: sport,
                club: club,
                gender: gender,
                heightCm: height,
                weightKg: weight,
                maxHrBpm: maxHrBpm,
            });
            if (changedProfilePic !== null) {
                try {
                    await Api.uploadProfilePicture(user!.id, changedProfilePic);
                } catch (e) {
                    showError(t('image upload failed'));
                    console.log(e);
                }
            }
            await reloadUser();
            setSaving(false);
            success = true;
        } catch (err) {
            setChanged(true);
            setSaving(false);
            success = false;
        }
        if (!success) showError('an error happened please try later');
    }

    const deleteAccount = async () => {
        const success = await Api.accountDelete(user!.id);
        if (!success) {
            showError(t('an error happened please try later'));
            return;
        }
        logout();
    }

    const confirmOnceMore = () => {
        openDialog(
            <YesCancelDialog
                title={t('please confirm')}
                subtitle={t('do you want to delete your account')}
                onYes={deleteAccount}
            />
        );
    }

    const onDeleteAccountWrapper = async () => {
        openDialog(
            <YesCancelDialog
                title={t('are you sure')}
                subtitle={t('account deletion is not reversible')}
                onYes={confirmOnceMore}
            />
        );
    }

    return (
        <DashboardLayout>
            <SizedBox height={8} />
            <div className={styles.topRow}>
                <div className={styles.title}>{t('profile')}</div>
                <div className={styles.deleteAccount} onClick={onDeleteAccountWrapper}>{t('delete account')}</div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className={styles.container}>
                    <div>
                        <label htmlFor="profile-pic-input">
                            <img ref={profilePictureRef} src={user!.profilePictureUrl} className={styles.profilePic} />
                        </label>
                        <input
                            className={styles.hidden}
                            id="profile-pic-input"
                            type="file"
                            onChange={setFile}
                            accept=".jpg,.jpeg,.png,image/jpeg,image/png"
                        />
                    </div>
                    <div className={styles.name}>{user!.name}</div>
                    <div>{user!.email}</div>
                    <SizedBox height={10} />
                    <div className={styles.settingsRow}>
                        <TextInput
                            classNameExtender={styles.clubName}
                            value={club}
                            onChange={(e) => { setClub(e.target.value); }}
                            label={t('club')}
                        />
                    </div>
                    <div className={styles.settingsRow}>
                        <Labelled label={t('gender')}>
                            <Selector
                                value={gender}
                                setValue={setGender}
                                labels={[t('female'), t('male'), t('other')]}
                                values={[GenderType.Woman, GenderType.Man, GenderType.Other]}
                            />
                        </Labelled>
                        <div className={styles.vDivider} />
                        <Labelled label={t('birth date')}>
                            <DateSelector
                                date={birthDate}
                                minYear={minYear}
                                maxYear={maxYear}
                                setDate={setBirthDate}
                                highlighted={true}
                            />
                        </Labelled>
                        <div className={styles.vDivider} />
                        <Labelled label={t('sport')}>
                            <Selector
                                value={sport}
                                setValue={setSport}
                                labels={[t('kayak'), t('canoe'), t('sup'), t('rowing'), t('sculling'), t('dragon boat')]}
                                values={[SportType.Kayaking, SportType.Canoeing, SportType.Suping, SportType.Rowing, SportType.Sculling, SportType.DragonBoating]}
                            />
                        </Labelled>
                    </div>
                    <div className={styles.settingsRow}>
                        <Labelled label={t('height')}>
                            <Selector
                                value={height}
                                setValue={setHeight}
                                labels={heights.map(h => `${h} cm`.toString())}
                                values={heights}
                            />
                        </Labelled>
                        <div className={styles.vDivider} />
                        <Labelled label={t('weight')}>
                            <Selector
                                value={weight}
                                setValue={setWeight}
                                labels={weights.map(w => `${w} kg`.toString())}
                                values={weights}
                            />
                        </Labelled>
                        <div className={styles.vDivider} />
                        <Labelled label={t('max heart rate')}>
                            <Selector
                                value={maxHrBpm}
                                setValue={setMaxHrBpm}
                                labels={bpms.map(w => `${w} bpm`.toString())}
                                values={bpms}
                            />
                        </Labelled>
                    </div>
                    <SizedBox height={10} />
                    {saving &&
                        <div className={styles.savingText}>
                            {t('saving')}
                        </div>
                    }
                    {!saving &&
                        <Button
                            label={t('save')}
                            disabled={!changed}
                            onClick={onSave}
                        />
                    }
                </div>
                <SizedBox width={10} />
                <div className={styles.hrZones}>
                    <div className={styles.zonesArea}>
                        {[1, 2, 3, 4, 5].map((zone) => (
                            <div className={styles.zone} key={`${zone}`}>
                                <div className={styles.zoneLabel}>{t('zone x', { zone })}</div>
                                <div className={styles.outliner} />
                            </div>
                        ))}
                    </div>
                    <div className={styles.zoneDetailsArea}>
                        {[50, 60, 70, 80, 90, 100].map((percentage) => (
                            <div className={styles.detailsContainer} key={`${percentage}`}>
                                <div className={styles.bpmLabel}>{`${Math.round(percentage / 100 * maxHrBpm)} bpm`}</div>
                                <div className={styles.percentageLabel}>{`${percentage} %`}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <SizedBox height={16} />
            {coachingRequests !== null && coachingRequests.length !== 0 &&
                <>
                    <div className={styles.coachingHeadline}>
                        <div className={styles.title}>{t('coaching requests')}</div>
                        <div className={styles.requestCounter}>{coachingRequests.length}</div>
                    </div>
                    <div className={styles.coachingSubtitle}>
                        {t('coaching requests subtitle')}
                    </div>
                </>
            }
            {coachingRequests !== null &&
                <div className={styles.listBox}>
                    {coachingRequests.map(req => (
                        <div className={styles.coach}>
                            <div className={styles.coachName}>{req.coachEmail}</div>
                            <Spacer flex={1} />
                            <Button
                                label={t('reject')}
                                secondary={true}
                                onClick={() => deleteCoachingRequest(req)}
                            />
                            <SizedBox width={8} />
                            <Button
                                label={t('accept')}
                                onClick={() => acceptCoachingRequest(req)}
                            />
                        </div>
                    ))}
                </div>
            }
        </DashboardLayout >
    );
};
