import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import styles from "./UserDetailsPage.module.scss";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Api} from "../../utils/api";
import {IUser} from "../../typings/IUser";
import {UserType} from "../../typings/UserType";
import moment from "moment";
import {formatSportType} from "../../typings/SportType";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {genderToString} from "../../typings/GenderType";

function userTypeToString(userType: UserType) {
    switch (userType) {
        case 1:
            return "Athlete";
        case 2:
            return "Coach";
        case 3:
            return "Admin";
    }
}

export const UserDetailsPage = () => {
    let {userId} = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState<IUser | null>(null);

    const openUser = (x: any) => {
        navigate(`/users/${x.id}`);
    }

    useEffect(() => {
        if (userId)
            Api.getUser(userId)
                .then(user => {
                    setUser(user);
                }).catch(console.log);
    }, [userId]);

    return (
        <DashboardLayout>
            <SizedBox height={8}/>
            <div className={styles.header}>
                <div>
                    <div className={styles.nameText}>
                        User - {user && user.name}
                        {user && <img src={user.profilePictureUrl} style={{
                            marginLeft: 20, width: 32, height: 32
                        }}/>}
                    </div>
                </div>
            </div>

            <SizedBox height={16}/>
            {user &&
                <div style={{padding: "0 20px"}}>
                    <Form>
                        <Row>
                            <Col md={1}><FormGroup>
                                <Label for="id">Id</Label>
                                <Input id="id" value={user.id} readOnly/>
                            </FormGroup></Col>
                            <Col md={3}><FormGroup>
                                <Label for="name">Name</Label>
                                <Input id="name" value={user.name} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>Sport</Label>
                                <Input value={formatSportType(user.sport)} readOnly/>
                            </FormGroup></Col>
                            <Col md={4}><FormGroup>
                                <Label>Club</Label>
                                <Input value={user.club} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>User type</Label>
                                <Input value={userTypeToString(user.userType)} readOnly/>
                            </FormGroup></Col>


                            <Col md={2}><FormGroup>
                                <Label>CloudId</Label>
                                <Input value={user.cloudId || ""} readOnly/>
                            </FormGroup></Col>
                            <Col md={3}><FormGroup>
                                <Label>Email</Label>
                                <Input value={user.email} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>Created at</Label>
                                <Input value={moment(user.createdAt).format("L")} readOnly/>
                            </FormGroup></Col>
                            <Col md={1}><FormGroup>
                                <Label>Country</Label>
                                <Input value={user.country || ""} readOnly/>
                            </FormGroup></Col>
                            <Col md={1}><FormGroup>
                                <Label>Language</Label>
                                <Input value={user.language || ""} readOnly/>
                            </FormGroup></Col>
                        </Row>
                        <Row>
                            <Col md={2}><FormGroup>
                                <Label>Birth date</Label>
                                <Input value={moment(user.birthDate).format("L")} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>Gender</Label>
                                <Input value={genderToString(user.gender)} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>Height (cm)</Label>
                                <Input value={user.heightCm} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>Weight (kg)</Label>
                                <Input value={user.weightKg} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>Max HR (bpm)</Label>
                                <Input value={user.maxHrBpm} readOnly/>
                            </FormGroup></Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col md={2}><FormGroup>
                                <Label>OS</Label>
                                <Input value={user.os || ""} readOnly/>
                            </FormGroup></Col>
                            <Col md={2}><FormGroup>
                                <Label>OS version</Label>
                                <Input value={user.osVersion || ""} readOnly/>
                            </FormGroup></Col>
                        </Row>
                    </Form>
                    <br/>
                    <br/>
                    <Link to={`/trainings?user=${user.id}`}>Trainings</Link>
                    <br/>
                    <br/>
                    <span style={{fontWeight: 700}}>Athletes</span>
                    {user.athletes.map(x => <div
                        onClick={() => openUser(x)}
                        style={{padding: "6px 0", cursor: "pointer"}}>
                        {x.name} ({x.id})
                    </div>)}
                    <br/>
                    <span style={{fontWeight: 700}}>Coaches</span>
                    {user.coaches.map(x => <div
                        onClick={() => openUser(x)}
                        style={{padding: "6px 0", cursor: "pointer"}}>
                        {x.name} ({x.id})
                    </div>)}
                </div>
            }
        </DashboardLayout>
    );
};
