import {ReactNode, RefObject, useEffect, useRef} from "react";
import styles from "./Dropdown.module.css";

interface IDropdown {
    anchor: RefObject<HTMLDivElement>;
    align?: "left" | "right";
    children: ReactNode;
    setOpen: (open: boolean) => void;
    className?: string;
}

export const Dropdown = ({anchor, align, children, setOpen, className}: IDropdown) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const anchorRect = anchor.current?.getBoundingClientRect();
    const {clientWidth, clientHeight} = document.documentElement;
    align ??= "right"

    useEffect(() => {
        containerRef.current?.focus();
    }, []);

    if (anchorRect === undefined) {
        return <></>;
    }

    let maxHeight = clientHeight - anchorRect.bottom - 70;
    if (maxHeight <= 40) maxHeight = 40;

    let right = undefined
    let left = undefined;
    switch (align) {
        case "left":
            right = undefined;
            left = anchorRect.left;
            break;
        case "right":
            right = clientWidth - anchorRect.right;
            left = undefined;
            break;
    }
    return (
        <div
            ref={containerRef}
            className={`${styles.container} ${className ? className : ''}`}
            style={{top: anchorRect.bottom, right, left, maxHeight: maxHeight}}
            tabIndex={-1}
            onBlur={() => setOpen(false)}
        >
            {children}
        </div>
    );
}
