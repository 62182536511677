import { mdiCog } from '@mdi/js';
import Icon from '@mdi/react';
import { useContext } from 'react';
import DialogContext from '../../../../contexts/DialogContext';
import { SeriesType } from '../../../../typings/SeriesType';
import { ChartSelectorDialog } from '../../../dialogs/ChartSelectorDialog/ChartSelectorDialog';
import styles from './ChartSelector.module.css';
import {useTranslation} from "react-i18next";

interface IChartSelector {
    charts: SeriesType[];
    syncDetailedForceView: boolean;
    enabledCharts: SeriesType[];
    onDone: (charts: SeriesType[], syncDetailedForceView: boolean) => void;
}

export const ChartSelector = ({ charts, enabledCharts, syncDetailedForceView, onDone }: IChartSelector) => {
    const { openDialog } = useContext(DialogContext);
    const { t } = useTranslation();

    const open = () => {
        openDialog(<ChartSelectorDialog initialCharts={charts} enabledCharts={enabledCharts}
                                        initialSyncDetailedForceView={syncDetailedForceView} onDone={onDone} />)
    }

    return (
        <div
            onClick={open}
            className={styles.selector}
        >
            <div className={styles.cogContainer}>
                <Icon path={mdiCog} size={1.2} />
                {t("settings")}
            </div>
        </div>
    );
};
