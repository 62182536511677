import { useContext } from 'react';
import DialogContext from '../../../contexts/DialogContext';
import styles from './DialogContainer.module.css';

export const DialogContainer = () => {
    const { dialogs } = useContext(DialogContext);

    const dialogArray = dialogs.slice();

    return (
        <>
            {dialogArray.length > 0 && <div className={styles.dialogBackdrop} />}
            {dialogArray.map(elem => {
                return (
                    <div
                        key={elem.key}
                        className={styles.dialogContainer}
                    >
                        {{
                            ...elem.dialog,
                            props: {
                                ...elem.dialog.props,
                                dialogKey: elem.key,
                            }
                        }}
                    </div>
                );
            })}
        </>
    );
}
