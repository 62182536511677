import AsyncSelect from "react-select/async";
import {Api} from "../../../utils/api";
import {ActionMeta} from "react-select";
import {IUser} from "../../../typings/IUser";
import styles from "./AthleteSelector.module.css";
import {useContext} from "react";
import AthleteFilterContext from "../../../contexts/AthleteFilterContext";
import {Options, OptionsOrGroups} from "react-select/dist/declarations/src/types";

type UserOption = { value: IUser | null, label: string };
export const AthleteSelector = ({placeholder}: { placeholder?: string }) => {
    const {athlete, setAthlete} = useContext(AthleteFilterContext);
    const promiseOptions = async (inputValue: string,
                                  callback: (options: OptionsOrGroups<UserOption | null, any>) => void): Promise<Options<UserOption | null>> => {
        const q = inputValue.trim();
        if (q.length > 1) {
            const users = await Api.getUsers(q);
            return users.data;
        } else return [];
    };

    const onChange = (option: IUser | null, actionMeta: ActionMeta<IUser>) => {
        setAthlete(option);
    };

    return <div>
        <AsyncSelect cacheOptions
                     defaultOptions
                     loadOptions={promiseOptions}
                     placeholder={placeholder ?? "Athlete"}
                     value={athlete}
                     getOptionLabel={x => `${x.name} (${x.id})`}
                     isClearable
                     className={styles.reactSelectContainer}
                     onChange={onChange}/>
    </div>;
};
