import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogContext from "../../../contexts/DialogContext";
import { SeriesType } from "../../../typings/SeriesType";
import { Button } from "../../base/Button/Button";
import { SizedBox } from "../../base/SizedBox/SizedBox";
import { Toggle } from "../../base/Toggle/Toggle";
import DialogShim from "../DialogShim";
import styles from "./ChartSelectorDialog.module.css";
import {ITrainingCommon} from "../../../typings/ITrainingCommon";
import {isRowing} from "../../../typings/TrainingSport";

export const getAvailableSeries = (trainingData: ITrainingCommon): SeriesType[] => {
    const charts = [];
    if (trainingData.tempo500Data)
        charts.push(SeriesType.TEMPO_500S, SeriesType.TEMPO_500_ZONES);
    if (trainingData.trainings.some(x => x.leftPaddlingForceNSeries) ||
        trainingData.trainings.some(x => x.rightPaddlingForceNSeries))
        charts.push(SeriesType.PULLING_FORCE, SeriesType.FORCE_ZONES);
    if (trainingData.speedData)
        charts.push(SeriesType.SPEEDS, SeriesType.SPEED_ZONES);
    if (trainingData.tempoData)
        charts.push(SeriesType.TEMPOS, SeriesType.TEMPO_ZONES);
    if (trainingData.strokeData)
        charts.push(SeriesType.STROKES);
    if (trainingData.distancePerStrokeData)
        charts.push(SeriesType.DISTANCEPERSTROKE);
    if (trainingData.trainings.some(x => x.heartRateBpmSeries))
        charts.push(SeriesType.HEARTRATE, SeriesType.HEARTRATE_ZONES, SeriesType.HEARTRATEPLOTBANDS);
    if (trainingData.gpsData)
        charts.push(SeriesType.GPS);
    if (trainingData.trainings.some(x => x.detailedLeftPaddlingForceNSeries) ||
        trainingData.trainings.some(x => x.detailedRightPaddlingForceNSeries))
        charts.push(SeriesType.DETAILS);
    return charts;
};

export const getDefaultSeries = (trainingData: ITrainingCommon): SeriesType[] => {
    let charts = getAvailableSeries(trainingData);
    if (isRowing(trainingData.sport)) {
        charts = charts.filter(x => x !== SeriesType.SPEEDS && x !== SeriesType.SPEED_ZONES &&
            x !== SeriesType.TEMPOS && x !== SeriesType.TEMPO_ZONES);
    } else {
        charts = charts.filter(x => x !== SeriesType.TEMPO_500S && x !== SeriesType.TEMPO_500_ZONES);
    }
    return charts;
};

interface IChartSelectorDialog {
    dialogKey?: string;
    initialCharts: SeriesType[];
    initialSyncDetailedForceView: boolean;
    enabledCharts: SeriesType[];
    onDone: (charts: SeriesType[], syncDetailedForceView: boolean) => void;
}

export const ChartSelectorDialog = ({ dialogKey: key, initialCharts, enabledCharts, initialSyncDetailedForceView, onDone }: IChartSelectorDialog) => {
    const dialogContext = useContext(DialogContext);
    const { t } = useTranslation();
    const [charts, setCharts] = useState<SeriesType[]>(initialCharts);
    const [syncDetailedForceView, setSyncDetailedForceView] = useState(initialSyncDetailedForceView);

    const onDismiss = () => dialogContext.dismissDialog(key!);
    if (key === undefined) console.warn("Dialog without a key opened!");

    const toggle = (s: SeriesType) => {
        const newCharts = charts.slice();
        const index = newCharts.findIndex(ss => s === ss);
        if (index === -1) {
            newCharts.push(s);
        } else {
            newCharts.splice(index, 1);
            let oIndex = -1;
            if (s === SeriesType.HEARTRATE) {
                oIndex = newCharts.findIndex(ss => ss === SeriesType.HEARTRATE_ZONES);
            } else if (s === SeriesType.SPEEDS) {
                oIndex = newCharts.findIndex(ss => ss === SeriesType.SPEED_ZONES);
            } else if (s === SeriesType.TEMPOS) {
                oIndex = newCharts.findIndex(ss => ss === SeriesType.TEMPO_ZONES);
            } else if (s === SeriesType.PULLING_FORCE) {
                oIndex = newCharts.findIndex(ss => ss === SeriesType.FORCE_ZONES);
            }
            if (oIndex !== -1) newCharts.splice(oIndex, 1);
        }
        setCharts(newCharts);
    };

    return (
        <DialogShim
            title={t("panel settings")}
            onClose={onDismiss}
        >
            <SizedBox height={8} />

            <div className={styles.grid}>

                <Toggle
                    label={t("map")}
                    value={charts.includes(SeriesType.GPS)}
                    onToggle={() => toggle(SeriesType.GPS)}
                    disabled={!enabledCharts.includes(SeriesType.GPS)}
                />

                <SizedBox height={16} />
                <SizedBox height={16} />
                <SizedBox height={16} />

                <Toggle
                    label={t("heartrate")}
                    value={charts.includes(SeriesType.HEARTRATE)}
                    onToggle={() => toggle(SeriesType.HEARTRATE)}
                    disabled={!enabledCharts.includes(SeriesType.HEARTRATE)}
                />
                <Toggle
                    label={t("heartrate zones")}
                    value={charts.includes(SeriesType.HEARTRATE_ZONES)}
                    onToggle={() => toggle(SeriesType.HEARTRATE_ZONES)}
                    disabled={!charts.includes(SeriesType.HEARTRATE) || !enabledCharts.includes(SeriesType.HEARTRATE_ZONES)}
                />
                <Toggle
                    label={t("speed")}
                    value={charts.includes(SeriesType.SPEEDS)}
                    onToggle={() => toggle(SeriesType.SPEEDS)}
                    disabled={!enabledCharts.includes(SeriesType.SPEEDS)}
                />
                <Toggle
                    label={t("speed zones")}
                    value={charts.includes(SeriesType.SPEED_ZONES)}
                    onToggle={() => toggle(SeriesType.SPEED_ZONES)}
                    disabled={!charts.includes(SeriesType.SPEEDS) || !enabledCharts.includes(SeriesType.SPEED_ZONES)}
                />
                <Toggle
                    label={t("tempo")}
                    value={charts.includes(SeriesType.TEMPOS)}
                    onToggle={() => toggle(SeriesType.TEMPOS)}
                    disabled={!enabledCharts.includes(SeriesType.TEMPOS)}
                />
                <Toggle
                    label={t("tempo zones")}
                    value={charts.includes(SeriesType.TEMPO_ZONES)}
                    onToggle={() => toggle(SeriesType.TEMPO_ZONES)}
                    disabled={!charts.includes(SeriesType.TEMPOS) || !enabledCharts.includes(SeriesType.TEMPO_ZONES)}
                />
                <Toggle
                    label={t("tempo split")}
                    value={charts.includes(SeriesType.TEMPO_500S)}
                    onToggle={() => toggle(SeriesType.TEMPO_500S)}
                    disabled={!enabledCharts.includes(SeriesType.TEMPO_500S)}
                />
                <Toggle
                    label={t("tempo split zones")}
                    value={charts.includes(SeriesType.TEMPO_500_ZONES)}
                    onToggle={() => toggle(SeriesType.TEMPO_500_ZONES)}
                    disabled={!charts.includes(SeriesType.TEMPO_500S) || !enabledCharts.includes(SeriesType.TEMPO_500_ZONES)}
                />
                <Toggle
                    label={t("stroke rate")}
                    value={charts.includes(SeriesType.STROKES)}
                    onToggle={() => toggle(SeriesType.STROKES)}
                    disabled={!enabledCharts.includes(SeriesType.STROKES)}
                />
                <SizedBox height={16} />
                <Toggle
                    label={t("distance per stroke")}
                    value={charts.includes(SeriesType.DISTANCEPERSTROKE)}
                    onToggle={() => toggle(SeriesType.DISTANCEPERSTROKE)}
                    disabled={!enabledCharts.includes(SeriesType.DISTANCEPERSTROKE)}
                />
                <SizedBox height={16} />
                <Toggle
                    label={t("pulling force")}
                    value={charts.includes(SeriesType.PULLING_FORCE)}
                    onToggle={() => toggle(SeriesType.PULLING_FORCE)}
                    disabled={!enabledCharts.includes(SeriesType.PULLING_FORCE)}
                />
                <Toggle
                    label={t("force zones")}
                    value={charts.includes(SeriesType.FORCE_ZONES)}
                    onToggle={() => toggle(SeriesType.FORCE_ZONES)}
                    disabled={!charts.includes(SeriesType.PULLING_FORCE) || !enabledCharts.includes(SeriesType.FORCE_ZONES)}
                />

                <SizedBox height={16} />
                <SizedBox height={16} />

                <Toggle
                    label={t("detailed force")}
                    value={charts.includes(SeriesType.DETAILS)}
                    onToggle={() => toggle(SeriesType.DETAILS)}
                    disabled={!enabledCharts.includes(SeriesType.DETAILS)}
                />

                <Toggle
                    label={t("sync detailed pulling forces view")}
                    value={syncDetailedForceView}
                    onToggle={() => setSyncDetailedForceView(x => !x)}
                />
            </div>

            <div className={styles.buttonRow}>
                <Button
                    label={t("cancel")}
                    onClick={onDismiss}
                    secondary={true}
                    className={styles.w120}
                />
                <Button
                    label={t("ok")}
                    onClick={() => {
                        onDone(charts, syncDetailedForceView);
                        onDismiss();
                    }}
                    className={styles.w120}
                />
            </div>
        </DialogShim>
    );
};
