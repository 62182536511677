import moment from 'moment';
import React, {useCallback, useState} from 'react';
import {GroupByPeriod} from '../typings/GroupByPeriod';

interface ITrainingsFilterContext {
    groupBy: GroupByPeriod;
    startDate: Date | null;
    endDate: Date | null;
    problemReported: boolean | null;
    problemResolved: boolean | null;
    deviceQuery: string | null;

    setGroupBy: (interval: GroupByPeriod) => void;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
    setProblemReported: (value: boolean | null) => void;
    setProblemResolved: (value: boolean | null) => void;
    setDeviceQuery: (value: string | null) => void;
    reset: () => void;
}

const defaultFilterContextValues: ITrainingsFilterContext = {
    groupBy: GroupByPeriod.Months,
    startDate: null,
    endDate: null,
    problemReported: null,
    problemResolved: null,
    deviceQuery: null,

    setGroupBy: () => {
    },

    setStartDate: () => {
    },
    setEndDate: () => {
    },
    setProblemReported: () => {
    },
    setProblemResolved: () => {
    },
    setDeviceQuery: () => {
    },

    reset: () => {
    },
};

export const TrainingsFilterContext = React.createContext(defaultFilterContextValues);

export const TrainingsFilterContextProvider = ({children}: { children: React.ReactNode }) => {
    const [groupBy, setGroupBy] = useState<GroupByPeriod>(GroupByPeriod.Months);
    const [startDate, setStartDate] = useState<Date | null>(moment().subtract(3, 'month').toDate());
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [problemReported, setProblemReported] = useState<boolean | null>(defaultFilterContextValues.problemReported);
    const [problemResolved, setProblemResolved] = useState<boolean | null>(defaultFilterContextValues.problemResolved);
    const [deviceQuery, setDeviceQuery] = useState<string | null>(null);

    const setStartDateWrapper = useCallback((newDate: Date | null) => {
        if (newDate !== null && moment(newDate).isSameOrAfter(moment(endDate))) {
            setEndDate(moment(newDate).add(1, groupBy).toDate());
        }
        setStartDate(newDate);
        // TODO other representation should be updated? Or representation switch function?
    }, [endDate, groupBy]);

    const setEndDateWrapper = useCallback((newDate: Date | null) => {
        if (newDate !== null && moment(newDate).isSameOrBefore(moment(startDate))) {
            setStartDate(moment(newDate).subtract(1, groupBy).toDate());
        }
        setEndDate(newDate);
    }, [startDate, groupBy]);

    const reset = useCallback(() => {
        const now = moment();
        setGroupBy(GroupByPeriod.Months);
        setStartDate(moment().startOf('month').subtract(3, 'month').toDate());
        setEndDate(now.toDate());
    }, []);

    return (
        <TrainingsFilterContext.Provider value={{
            groupBy,
            startDate,
            endDate,
            problemReported,
            problemResolved,
            deviceQuery,

            setGroupBy,
            setStartDate: setStartDateWrapper,
            setEndDate: setEndDateWrapper,
            setProblemReported,
            setProblemResolved,
            setDeviceQuery,

            reset,
        }}>
            {children}
        </TrainingsFilterContext.Provider>
    );
}

export default TrainingsFilterContext;
