import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import styles from './NavButton.module.css';

interface INavButton {
    icon: string;
    title: string;
    link?: string;
    onClick?: () => void
}

export const NavButton = ({ icon, title, link, onClick }: INavButton) => {
    const isActive = document.location.pathname === link;

    let color = '';
    if (isActive) {
        color = getComputedStyle(document.documentElement).getPropertyValue('--nav-active-color');
    } else {
        color = getComputedStyle(document.documentElement).getPropertyValue('--nav-inactive-color');
    }

    const content = <><Icon path={icon} size={0.8} color={color}/>
        <div className={`${styles.title} ${isActive ? styles.titleActive : ''}`}>
            {title}
        </div>
    </>;
    if (link) {
        return (
            <Link className={styles.container} to={link}>
                {content}
            </Link>
        );
    }
    return <div className={styles.container} onClick={onClick}>
        {content}
    </div>;
};
