import { mdiAccountOutline, mdiLogout } from "@mdi/js";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../contexts/UserContext";
import { Dropdown } from "../../../base/Dropdown/Dropdown";
import { MenuItem } from "../../../base/MenuItem/MenuItem";
import styles from "./SettingsDropdown.module.css";

export const SettingsDropdown = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, logout, incomingCoachingRequests } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLDivElement>(null);

    const cssColor = getComputedStyle(document.documentElement).getPropertyValue('--normal-error-color');

    const onProfile = () => {
        navigate('/profile');
    }

    return (
        <div className={styles.container} onClick={() => setOpen(!open)} ref={anchor}>
            <img className={styles.profilePicture} alt="" src={user?.profilePictureUrl ?? ""} />
            {(incomingCoachingRequests?.length ?? 0) > 0 &&
                <div className={styles.indicator}>{incomingCoachingRequests!.length}</div>
            }
            {open &&
                <Dropdown anchor={anchor} setOpen={setOpen}>
                    <MenuItem
                        onClick={onProfile}
                        title={t('profile')}
                        icon={mdiAccountOutline}
                    />
                    <MenuItem
                        onClick={logout}
                        title={t('logout')}
                        icon={mdiLogout}
                        iconColor={cssColor}
                    />
                </Dropdown>
            }
        </div>
    );
}
