import moment from "moment";

export const ChartTooltipContent = ({
                                        x, distance, points, unit, units,
                                        valueFormatter, timeFormat
                                    }: any) => {
    valueFormatter ??= (x: number) => x.toFixed(1);
    timeFormat ??= "H:mm:ss";
    return <>
        <div>
            {String(moment(x).utc().format(timeFormat))}
            {distance != null && <>
                <br/>
                {distance} km
            </>}
        </div>
        <div style={{marginLeft: 10}}>
            {points?.map((point: any, i: number) => {
                const name = point.name ? `${point.name}: ` : undefined
                return <div key={i} style={{color: point.color, fontWeight: 700}}>
                    {name}{valueFormatter(point.y)} {units ? units[i] : unit}
                </div>;
            })
            }
        </div>
    </>
}