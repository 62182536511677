import styles from './Toggle.module.css';

interface IToggle {
    value: boolean;
    onToggle: () => void;
    label: string;
    disabled?: boolean;
}

export const Toggle = ({ value, onToggle, label, disabled = false }: IToggle) => {
    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : ''}`} onClick={!disabled ? onToggle : undefined}>
            <div className={`${styles.bar} ${disabled ? styles.disabled : ''}`}>
                <div className={`${styles.handle} ${(value && !disabled) ? styles.active : ''} ${disabled ? styles.disabled : ''}`} />
            </div>
            <div className={styles.label}>
                {label}
            </div>
        </div>
    );
};
