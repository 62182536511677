export const sum = (a: number[]) => {
  return a.reduce((a, b) => a + b, 0);
}

export const avg = (a: number[]) => {
  return sum(a) / a.length || 0;
}

export const max = (a: number[]) => {
  return a.reduce((a, b) => b > a ? b : a, -Infinity);
}

export const min = (a: number[]) => {
  return a.reduce((a, b) => b < a ? b : a, Infinity);
}

export const minMax = (a: number[]) => {
  return a.reduce((a, b) => {
    if(a.min > b)
      a.min = b;
    else if(a.max < b)
      a.max = b;
    return a;
  }, {min: Infinity, max: -Infinity});
}