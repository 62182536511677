import { App } from "./App";
import { AthleteFilterContextProvider } from "./contexts/AthleteFilterContext";
import { DialogContextProvider } from "./contexts/DialogContext";
import { TrainingsFilterContextProvider } from "./contexts/TrainingsFilterContext";
import { ResponsiveContextProvider } from "./contexts/ResponsiveContext";
import { UserContextProvider } from "./contexts/UserContext";
import {StatisticsContextProvider} from "./contexts/StatisticsContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient()

export const AppWrapper = () => (
    <QueryClientProvider client={queryClient}>
        <ResponsiveContextProvider>
            <DialogContextProvider>
                <TrainingsFilterContextProvider>
                    <StatisticsContextProvider>
                        <UserContextProvider>
                            <AthleteFilterContextProvider>
                                <App />
                            </AthleteFilterContextProvider>
                        </UserContextProvider>
                    </StatisticsContextProvider>
                </TrainingsFilterContextProvider>
            </DialogContextProvider>
        </ResponsiveContextProvider>
    </QueryClientProvider>
);
