import React, { useCallback, useRef, useState } from 'react';
import OkDialog from '../components/dialogs/OkDialog/OkDialog';

interface IDialog {
    dialog: JSX.Element;
    key: string;
}

interface IDialogContext {
    dialogs: IDialog[],

    openDialog: (modal: JSX.Element) => void;
    dismissDialog: (key: string) => void;

    showError: (message: string) => void;
}

const defaultDialogContextValues: IDialogContext = {
    dialogs: [],

    openDialog: (modal: JSX.Element) => { },
    dismissDialog: (key) => { },

    showError: (message) => { },
};

export const DialogContext = React.createContext(defaultDialogContextValues);

const genKey = () => {
    const rnd = Math.floor(Math.random() * 10000);
    return `u-${Date.now()}${rnd}`;
}

export const DialogContextProvider = ({ children }: { children: React.ReactNode }) => {
    const dialogsRef = useRef<IDialog[]>([]);
    const [dialogs, setDialogs] = useState<IDialog[]>([]);

    const openDialog = useCallback((dialog: JSX.Element) => {
        const key = genKey();
        const newDialogs = dialogsRef.current.slice();
        newDialogs.push({ dialog, key });
        setDialogs(newDialogs);
        dialogsRef.current = newDialogs;
        return key;
    }, []);

    const dismissDialog = (key: string) => {
        const newDialogs = dialogsRef.current.slice();
        const index = newDialogs.findIndex(dialog => dialog.key === key);
        if (index === -1) return;
        newDialogs.splice(index, 1);
        setDialogs(newDialogs);
        dialogsRef.current = newDialogs;
    }

    const showError = useCallback((message: string) => {
        const errorDialog = <OkDialog dialogKey={genKey()} message={message} />;
        openDialog(errorDialog);
    }, [openDialog])

    return (
        <DialogContext.Provider value={{
            dialogs,

            openDialog,
            dismissDialog,

            showError,
        }}>
            {children}
        </DialogContext.Provider>
    );
}

export default DialogContext;
