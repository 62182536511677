import { useRef, useState } from 'react';
import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownLabel } from '../DropdownLabel/DropdownLabel';
import { SelectItem } from '../SelectItem/SelectItem';
import styles from './Selector.module.css';

interface ISelector<T> {
    value: T;
    labels: string[];
    values: T[];
    setValue: (g: T) => void;
}

export const Selector = <T,>({ value, labels, values, setValue }: ISelector<T>) => {
    const anchor = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const selectedIndex = values.findIndex(v => v === value);
    const label = labels[selectedIndex === -1 ? 0 : selectedIndex];

    return (
        <div
            ref={anchor}
            className={styles.container}
            onClick={isOpen ? undefined : () => setIsOpen(true)}
        >
            <DropdownLabel
                label={label}
                open={isOpen}
            />
            {isOpen &&
                <Dropdown anchor={anchor} setOpen={setIsOpen}>
                    {labels.map((l, i) => (
                        <SelectItem
                            key={labels[i]}
                            label={l}
                            onClick={() => { setIsOpen(false); setValue(values[i]); }}
                        />
                    ))}
                </Dropdown>
            }
        </div>
    );
};
