import moment from "moment";
import {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ExactTimeSelector} from "../../components/base/ExactTimeSelector/ExactTimeSelector";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {Spinner} from "../../components/base/Spinner/Spinner";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import {AthleteSelector} from "../../components/paddlemate/AthleteSelector/AthleteSelector";
import InfiniteScroll from "react-infinite-scroll-component";
import AthleteFilterContext from "../../contexts/AthleteFilterContext";
import TrainingsFilterContext from "../../contexts/TrainingsFilterContext";
import TrainingsContext from "../../contexts/TrainingsContext";
import styles from "./TrainingsPage.module.scss";
import {GroupByPeriod} from "../../typings/GroupByPeriod";
import {ParamKeyValuePair, useNavigate, useSearchParams} from "react-router-dom";
import {TrainingRow} from "../../components/paddlemate/TrainingRow/TrainingRow";
import {usePrevious} from "../../utils/usePrevious";
import {Api} from "../../utils/api";
import {IApiTrainingCommonBase} from "../../typings/IApiTrainingCommonBase";
import {Toggle} from "../../components/base/Toggle/Toggle";
import {Input} from "reactstrap";

export const TrainingsPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {trainingCommons, loadMore, hasMore} = useContext(TrainingsContext);
    const {
        startDate, endDate, problemReported, problemResolved, groupBy, setStartDate, setEndDate,
        setProblemReported, setProblemResolved, deviceQuery, setDeviceQuery
    } = useContext(TrainingsFilterContext);
    const {athlete, setAthlete} = useContext(AthleteFilterContext);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const user = searchParams.get("user");
        if (user !== null) {
            Api.getUser(user).then((u) => {
                setAthlete(u);
            })
        }
    }, [searchParams, setAthlete]);
    useEffect(() => {
        const newSearchParams: ParamKeyValuePair[] = [];
        if (athlete)
            newSearchParams.push([
                "user", athlete.id.toString()
            ])
        setSearchParams(newSearchParams)
    }, [setSearchParams, athlete]);
    const keyFunction = (tc: IApiTrainingCommonBase) => {
        switch (groupBy) {
            case GroupByPeriod.Years:
                return moment(tc.startAt).year().toString();
            case GroupByPeriod.Months:
                return moment(tc.startAt).format("YYYY MMM").toString();
            case GroupByPeriod.Weeks:
                return moment(tc.startAt).format("YYYY ").toString() + t("week x", {week: moment(tc.startAt).startOf("isoWeek").week().toString()});
        }
        return "";
    };
    const trainingCommonsWithHeaders: ({
        type: "group-header" | "item",
        value: string | IApiTrainingCommonBase,
        groupIndex: number
    })[] = trainingCommons.map(x => ({type: "item", value: x, groupIndex: 0}));
    let lastKey = "";
    let groupIndex = -1;
    for (let i = 0; i < trainingCommonsWithHeaders.length; i++) {
        const tcwh = trainingCommonsWithHeaders[i];
        // @ts-ignore
        const key = keyFunction(tcwh.value);
        if (key !== lastKey) {
            groupIndex++;
            trainingCommonsWithHeaders.splice(i, 0, {type: "group-header", value: key, groupIndex});
            i++;
            lastKey = key;
        }
        tcwh.groupIndex = groupIndex;
    }

    const handleClearFilter = () => {
        setStartDate(null);
        setEndDate(null);
    };

    const handleTrainingClick = (training: IApiTrainingCommonBase) => {
        navigate(`/trainings/${training.id}`);
    };

    // To fix InfiniteScroll loads bad pages after filter change
    const trainingCommonsWithHeadersPrev = usePrevious(trainingCommonsWithHeaders);
    const scrollableDiv = useRef<HTMLDivElement | null>(null);
    if (trainingCommonsWithHeadersPrev != null && trainingCommonsWithHeadersPrev.length > trainingCommonsWithHeaders.length) {
        scrollableDiv.current?.scrollTo(0, 0);
    }

    return (
        <DashboardLayout scrollable={false}>
            <SizedBox height={8}/>
            <div className={styles.header}>
                <div className={styles.nameText}>
                    {t("training list")}
                </div>
            </div>
            <SizedBox height={8}/>
            <div className={styles.filter}>
                <div className={styles.date}>
                    <ExactTimeSelector startDate={startDate} endDate={endDate} setStartDate={setStartDate}
                                       setEndDate={setEndDate}/>
                    <button
                        type="button"
                        className="btn btn-link w-sm"
                        onClick={handleClearFilter}
                    >
                        {t("Clear")}
                    </button>
                    <SizedBox width={16}/>
                    <Toggle
                        label={"problem reported"}
                        onToggle={() => setProblemReported(problemReported ? null : true)}
                        value={problemReported || false}
                    />
                    {problemReported && <Toggle
                        label={"problem resolved"}
                        onToggle={() => setProblemResolved(!problemResolved)}
                        value={problemResolved || false}
                    />}
                </div>
                <div className={"d-flex gap-4"}>
                    <AthleteSelector placeholder={"Created by"}/>
                    <Input style={{maxWidth: 150}} placeholder={"Device"} value={deviceQuery || ""}
                           onChange={(e) => setDeviceQuery(e.target.value)}/>
                </div>
            </div>

            <SizedBox height={16}/>
            <div id="scrollableDiv" className={styles.scrollParent} ref={scrollableDiv}>
                <InfiniteScroll
                    dataLength={trainingCommonsWithHeaders.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{display: "flex", alignItems: "center", justifyContent: "center", padding: 20}}>
                        <Spinner/>
                    </div>} // Loader component to show while loading
                    scrollableTarget="scrollableDiv"
                    style={{overflowY: "hidden"}}
                    endMessage={<div style={{
                        display: "flex", alignItems: "center", justifyContent: "center",
                        padding: 20, fontSize: 18
                    }}>
                        {t("no more trainings")}
                    </div>}
                >
                    <div className={styles.grid}>
                        {trainingCommonsWithHeaders.map((x, i) => {
                            if (x.type === "group-header") {
                                const row = x.groupIndex * 2 + i + 1;
                                let gcs = 1;
                                return <div className={styles.trainingRow} key={`group-header-${i}`}>
                                    <div className={styles.trainingCellHeader}
                                         style={{
                                             gridColumn: "1 / -1",
                                             gridRowStart: row
                                         }}>{x.value as string}</div>
                                    <div style={{gridColumnStart: gcs++, gridRowStart: row + 1}}></div>
                                    <div className={styles.trainingCellHeader}
                                         style={{gridColumnStart: gcs++, gridRowStart: row + 1}}>{t("date")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{gridColumnStart: gcs++, gridRowStart: row + 1}}>{t("sport")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{
                                             gridColumnStart: gcs++,
                                             gridRowStart: row + 1
                                         }}>{t("created by")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{gridColumnStart: gcs++, gridRowStart: row + 1}}>{t("name")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{gridColumnStart: gcs++, gridRowStart: row + 1}}>{t("duration")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{gridColumnStart: gcs++, gridRowStart: row + 1}}>{t("distance")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{gridColumnStart: gcs++, gridRowStart: row + 1}}>{t("heartrate")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{
                                             gridColumnStart: gcs++,
                                             gridRowStart: row + 1
                                         }}>{t("pulling force")}</div>
                                    <div className={styles.trainingCellHeader}
                                         style={{
                                             gridColumnStart: gcs++,
                                             gridRowStart: row + 1
                                         }}>{t("stroke rate")}</div>
                                </div>;
                            } else {
                                const row = x.groupIndex * 2 + i + 2;
                                const tc = x.value as IApiTrainingCommonBase;
                                let avgHr: number | null = tc.trainings.reduce(
                                    (acc, t) =>
                                        acc + (t.avgHeartRateBpm ?? 0), 0) / tc.trainings.length;
                                let avgPf: number | null = tc.trainings.reduce(
                                    (acc, t) =>
                                        acc + (t.avgPaddlingForceN ?? 0), 0) / tc.trainings.length;
                                if (avgHr === 0) avgHr = null;
                                if (avgPf === 0) avgPf = null;
                                return <TrainingRow key={`r-${i + 3}`} i={row}
                                                    t={tc.trainings[0]} tc={tc}
                                                    forAthlete={false}
                                                    onClick={() => handleTrainingClick(tc)}/>;
                            }
                        })}
                    </div>
                </InfiniteScroll>
            </div>
        </DashboardLayout>
    );
};
