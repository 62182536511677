import moment from 'moment';
import {getSportIcon} from '../../../typings/SportType';
import {trainingSportMapping} from '../../../typings/TrainingSport';
import {Formatter} from '../../../utils/formatter';
import styles from './TrainingRow.module.css';
import {IApiTrainingCommonBase, TrainingSource} from "../../../typings/IApiTrainingCommonBase";
import {IApiTrainingCommonTraining} from "../../../typings/IApiTrainingWithCommonTraining";

function toTrainingSourceName(source: TrainingSource) {
    switch (source) {
        case TrainingSource.Phone:
            return "P";
        case TrainingSource.Tracker:
            return "T";
        case TrainingSource.External:
            return "E";
    }
}

export const TrainingRow = ({i, t, tc, forAthlete, onClick}: {
    i: number,
    t: IApiTrainingCommonTraining,
    tc: IApiTrainingCommonBase,
    forAthlete: boolean,
    onClick: () => void,
}) => {
    let j = 1;
    return <div className={styles.trainingRow} key={`r-${i}`}>
        <div style={{gridColumn: `1 / -1`, gridRow: `${i} / ${i + 1}`}} className={styles.trainingRowBg}/>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCellNoPadding}>
            {toTrainingSourceName(tc.source)}
            {tc.reportedProblem && !tc.reportedProblemResolved && "⚠️"}
            {tc.reportedProblem && tc.reportedProblemResolved && "✅"}
        </div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{moment(tc.startAt).format("YYYY-MM-DD HH:mm")}</div>
        <div className={styles.trainingCell} key={`${i}-2`} onClick={onClick}
             style={{gridColumnStart: j++, gridRowStart: i}}>
            <img className={styles.sportIcon} src={getSportIcon(trainingSportMapping(tc.sport))}/>
        </div>
        {!forAthlete && <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
                             className={styles.trainingCell}>
            <div className={styles.createdByName}>
                {tc.createdBy.name}
            </div>
        </div>}
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>
            <div className={styles.trainingName}>
                {tc.name}
            </div>
        </div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{Formatter.secToTimeString(moment(tc.endAt).diff(moment(tc.startAt), 'second'))}</div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{tc.lengthKm?.toFixed(2)} km
        </div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{t.avgHeartRateBpm?.toFixed(2)} {t.avgHeartRateBpm ? 'bpm' : ''}</div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{t.avgPaddlingForceN?.toFixed(2)} {t.avgPaddlingForceN ? 'N' : ''}</div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{tc.avgStrokeRateSpm?.toFixed(2)} {tc.avgStrokeRateSpm ? ' / min' : ''}</div>
    </div>;
};
