import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {TimeSelector} from "../../components/base/TimeSelector/TimeSelector";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import {GranularitySelector} from "../../components/paddlemate/GranularitySelector/GranularitySelector";
import ResponsiveContext from "../../contexts/ResponsiveContext";
import styles from "./DashboardPage.module.css";
import StatisticsContext from "../../contexts/StatisticsContext";
import {StatCategory} from "../../typings/StatCategory";
import {GroupByPeriod} from "../../typings/GroupByPeriod";
import {Formatter} from "../../utils/formatter";
import {Api} from "../../utils/api";
import {TimeInterval} from "../../typings/TimeInterval";
import {IAdminTrainingStatisticsData} from "../../typings/IAdminTrainingStatisticsData";
import {IAdminUserStatisticsData} from "../../typings/IAdminUserStatisticsData";
import {formatSportType, SportType} from "../../typings/SportType";
import {Col, Container, Row} from "reactstrap";
import {genderToString, GenderType} from "../../typings/GenderType";

export const DashboardPage = () => {
    const {t} = useTranslation();
    const {chartWidth} = useContext(ResponsiveContext);
    const {timeGrouping, startDate, endDate, granularity, timeInterval} = useContext(StatisticsContext);

    const columnSeriesDefault = useMemo(() => ({
        dataLabels: {
            enabled: false,
        },
        label: {
            enabled: false,
        },
        name: undefined,
        showInLegend: false,
        data: [],
    }), []);
    const defaultColumnOptions = useMemo(() => ({
        chart: {
            type: "column",
            width: chartWidth,
            height: 300,
        },
        tooltip: {
            outside: true,
            useHTML: true
        }
    }), [chartWidth]);

    const defaultColumnXAxis = useMemo(() => ({
        tickWidth: 1,
        categories: [] as string[],
        labels: {
            enabled: true,
        },
        tickLength: 1,
    }), []);

    const weekDays = useMemo(() => moment.localeData().weekdays(), []);
    const months = useMemo(() => moment.localeData().months(), []);

    const formatX = useCallback((x: number): string => {
        if (granularity === GroupByPeriod.Days) {
            if (timeInterval === TimeInterval.Week)
                return weekDays[(x + 1) % 7];
            else
                return x.toString();
        } else if (granularity === GroupByPeriod.Weeks) {
            return t("week x", {week: x});
        } else if (granularity === GroupByPeriod.Months) {
            return months[(x - 1) % 12];
        }
        return x.toString();
    }, [weekDays, months, granularity, t, timeInterval]);

    const createColumnOptions = useCallback((title: string, total: number, formatter: any) =>
            ({
                ...defaultColumnOptions,
                title: {
                    text: `${title} (${formatter(total)})`
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    allowDecimals: false
                },
                tooltip: {
                    ...defaultColumnOptions.tooltip,
                    formatter: function (): string {
                        const self = this as any;
                        return `<div><b>${self.x}</b>: <b>${formatter(self.y)}</b></div>`;
                    }
                }
            })
        , [defaultColumnOptions]);

    const [trainingStats, setTrainingStats] = useState<IAdminTrainingStatisticsData | null>(null);
    const [userStats, setUserStats] = useState<IAdminUserStatisticsData | null>();

    const sessionsFormatter = useCallback((x: number) => x.toString(), []);
    const durationFormatter = useCallback((x: number) => `${Formatter.secToTimeString(x)}`, []);
    const lengthFormatter = useCallback((x: number) => `${x.toFixed(2)} km`, []);

    const trainingStatsOptions = useMemo(() => {
        if (!trainingStats)
            return null;
        return [
            {
                ...createColumnOptions(t(StatCategory.Sessions), trainingStats.trainingSessions, sessionsFormatter),
                xAxis: {
                    ...defaultColumnXAxis,
                    categories: Object.keys(trainingStats.trainingSessionsSeries).map(x => formatX(parseInt(x)))
                },
                series: [{
                    ...columnSeriesDefault,
                    type: "column",
                    data: Object.values(trainingStats.trainingSessionsSeries)
                }],
            },
            {
                ...createColumnOptions(t(StatCategory.Duration), trainingStats.durationS, durationFormatter),
                xAxis: {
                    ...defaultColumnXAxis,
                    categories: Object.keys(trainingStats.durationSSeries).map(x => formatX(parseInt(x)))
                },
                series: [{...columnSeriesDefault, type: "column", data: Object.values(trainingStats.durationSSeries)}],
            },
            {
                ...createColumnOptions(t(StatCategory.Distance), trainingStats.lengthKm, lengthFormatter),
                xAxis: {
                    ...defaultColumnXAxis,
                    categories: Object.keys(trainingStats.lengthKmSeries).map(x => formatX(parseInt(x)))
                },
                series: [{...columnSeriesDefault, type: "column", data: Object.values(trainingStats.lengthKmSeries)}],
            }
        ];
    }, [createColumnOptions, defaultColumnXAxis, durationFormatter, formatX, lengthFormatter, columnSeriesDefault, sessionsFormatter, t, trainingStats]);

    const createPieOptions = useCallback((title: string, seriesName: string, data: any) => ({
        chart: {
            type: "pie",
        },
        title: {
            text: title,
        },
        tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %"
                },
                size: "50%"
            }
        },
        series: [{
            name: seriesName,
            data: data
        }]
    }), []);

    const createStackedColumnOptions = useCallback((title: string, seriesName: string, series: any) => ({
        chart: {
            type: "column"
        },
        title: {
            text: title,
        },
        xAxis: {
            type: "datetime",
            tickInterval: 1000 * 3600 * 24 * 30
        },
        yAxis: {
            title: {
                text: ""
            }
        },
        series: series
    }), []);

    const userStatsPieOptions = useMemo(() => {
        if (!userStats)
            return null;
        return [
            createPieOptions("User sports", "Users", Object.entries(userStats.usersBySport)
                .map(([sport, count]) =>
                    ({name: formatSportType(parseInt(sport) as SportType), y: count}))),
            createPieOptions("User ages", "Users", Object.entries(userStats.usersByAgeCategory)
                .map(([age, count]) =>
                    ({name: age, y: count}))),
            createPieOptions("User gender", "Users", Object.entries(userStats.usersByGender)
                .map(([gender, count]) =>
                    ({name: genderToString(parseInt(gender) as GenderType), y: count}))),
            createPieOptions("Coaches/Athletes", "Coaches/Athletes", Object.entries(userStats.usersByIsCoach)
                .map(([isCoach, count]) =>
                    ({name: isCoach === "True" ? "Coach" : "Athlete", y: count})))
        ];
    }, [userStats, createPieOptions]);

    const userStatsLineOptions = useMemo(() => {
        if (!userStats)
            return null;
        return [
            createStackedColumnOptions("Users", "Users", [
                {
                    name: "Users",
                    data: Object.entries(userStats.userCountByDate)
                        .map(x => [parseInt(x[0]), x[1]])
                        .sort((a, b) => a[0] - b[0])
                }])
        ];
    }, [userStats, createStackedColumnOptions]);


    useEffect(() => {
        let cancelled = false;
        const load = async () => {
            const trainingStats = await Api.getAdminStatisticsTraining(timeGrouping,
                startDate || new Date(0), endDate || moment().toDate());
            if (cancelled) return;
            setTrainingStats(trainingStats);
        };
        load().catch(console.log);

        return () => {
            cancelled = true;
        };
    }, [createColumnOptions, defaultColumnXAxis, endDate, columnSeriesDefault, startDate, t, timeGrouping, formatX]);

    useEffect(() => {
        let cancelled = false;
        const load = async () => {
            const stats = await Api.getAdminStatisticsUser();
            if (cancelled) return;
            setUserStats(stats);
        };
        load().catch(console.log);

        return () => {
            cancelled = true;
        };
    }, []);

    return (
        <DashboardLayout>
            <SizedBox height={8}/>
            <div className={styles.nameRow}>
                <div className={styles.nameText}>
                    Dashboard
                </div>
            </div>
            <TimeSelector/>
            {trainingStatsOptions && <>
                <div className={styles.table}>
                    <b>Total</b>
                    <table>
                        <thead>
                        <tr>
                            <th>Sessions</th>
                            <th>Time</th>
                            <th>Distance</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{sessionsFormatter(trainingStats?.trainingSessions || 0)}</td>
                            <td>{durationFormatter(trainingStats?.durationS || 0)}</td>
                            <td>{lengthFormatter(trainingStats?.lengthKm || 0)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.table} style={{marginLeft: 50}}>
                    <b>Avg</b>
                    <table>
                        <thead>
                        <tr>
                            <th>Time</th>
                            <th>Distance</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{durationFormatter(trainingStats?.durationSAvg || 0)}</td>
                            <td>{lengthFormatter(trainingStats?.lengthKmAvg || 0)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {trainingStatsOptions.map((stat, i) =>
                    <div key={i}
                         style={{
                             position: "relative",
                             borderRadius: 16,
                             overflow: "hidden",
                         }}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={stat}
                        />
                        <GranularitySelector
                            className={styles.granularitySelector}
                        />
                    </div>
                )}
            </>}
            {userStatsPieOptions && <Container>
                <Row>
                    {userStatsPieOptions.map((s, i) =>
                        <Col md={4} key={i}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={s}
                            />
                        </Col>
                    )}
                </Row>
            </Container>}
            {userStatsLineOptions && <Container>
                <Row>
                    {userStatsLineOptions.map((s, i) =>
                        <Col md={12} key={i}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={s}
                            />
                        </Col>
                    )}
                </Row>
            </Container>}
            <div><h4>Users with created training: {userStats?.userCountWithCreatedTraining}</h4></div>
            <div><h4>Users in any training: {userStats?.userCountInAnyTraining}</h4></div>
            <div><h4>Coachings: {userStats?.coachings}</h4></div>
        </DashboardLayout>
    );
};
