import * as React from "react";
import {useEffect, useState} from "react";
import styles from "./LiveCharts.module.scss";
import {useTranslation} from "react-i18next";
import {LiveForce} from "./LiveForce";
import {LiveStats} from "./LiveStats";
import {AthleteToggle} from "../AnalyticsPanel/AthleteToggle/AthleteToggle";
import {IAthlete} from "../../../typings/IAthlete";
import {getLiveShortName} from "../../../utils/liveHelper";
import {ILiveBoat} from "../../../pages/LivePage/LivePage";


const selectedAthleteIndexByBoat: { [key: string]: number } = {};

export const LiveCharts = ({boat}: { boat: ILiveBoat | undefined }) => {
    const {t} = useTranslation();
    const [tabState, setTabState] = useState("force");
    const [selectedAthleteIndex, setSelectedAthleteIndex]
        = useState<number>(0);

    useEffect(() => {
        if (boat?.id) {
            let index = selectedAthleteIndexByBoat[boat.id];
            if (index !== undefined && index > boat.athletes.length - 1)
                index = 0;
            setSelectedAthleteIndex(index || 0);
        }
    }, [boat]);

    const handleAthleteClick = (i: number) => {
        setSelectedAthleteIndex(i);
        if (boat?.id) {
            selectedAthleteIndexByBoat[boat.id] = i;
        }
    };
    if (!boat)
        return <div className={styles.noAthlete}>{t("live stats no boat selected")}</div>
    return <div className={styles.stats}>
        <ul className={`${styles.nav} nav nav-pills pb-2`}>
            <li className="nav-item">
                <a role="button" className={
                    `nav-link ${tabState === "force" ? "active" : ""}`}
                   onClick={() => setTabState("force")}
                >
                    {t("live detailed forces")}</a>
            </li>
            <li className="nav-item">
                <a role="button" className={
                    `nav-link ${tabState === "stats" ? "active" : ""}`}
                   onClick={() => setTabState("stats")}
                >
                    {t("live statistics")}</a>
            </li>
            {tabState !== "force" && boat?.athletes?.length > 1 && <>
                <li className={styles.athletes}>
                    {boat?.athletes!.map((x, i) =>
                        <AthleteToggle key={i}
                                       isSelected={selectedAthleteIndex === i}
                                       shortName={false}
                                       onClick={() => handleAthleteClick(i)}
                                       athlete={{firstName: getLiveShortName(x.name), lastName: ""} as any as IAthlete}
                                       sport={boat.sport!}
                                       index={undefined}/>
                    )}
                </li>
            </>}
        </ul>
        <div className={styles.content}>
            {boat && <>
                {tabState === "force" && <LiveForce boat={boat}/>}
                {tabState === "stats" && <LiveStats boat={boat} athlete={boat.athletes[selectedAthleteIndex]}/>}</>}
        </div>
    </div>;
}