import { mdiEyeOutline } from '@mdi/js';
import Icon from "@mdi/react";
import { ChangeEventHandler, useState } from "react";
import { Labelled } from '../Labelled/Labelled';
import styles from "./TextInput.module.css";

interface ITextInput {
    type?: string;
    label?: string | null;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    className?: string;
    classNameExtender?: string;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
}

export const TextInput = ({
    type = "text",
    label = null,
    value,
    onChange,
    className,
    classNameExtender,
    placeholder,
    readOnly,
    disabled
}: ITextInput) => {
    const [visible, setVisible] = useState<boolean>(false);

    return (
        <Labelled label={label}>
            <div className={styles.boxRow}>
                <input
                    value={value}
                    className={`${className ?? styles.input} ${classNameExtender ? classNameExtender : ''} ${type === "password" ? styles.passwordInput : ""}`}
                    type={(type === "password" && visible) ? "text" : type}
                    onChange={onChange}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={disabled}
                />
                {type === "password" &&
                    <div
                        className={styles.visibleIndicator}
                        onClick={() => setVisible(!visible)}
                    >
                        <Icon path={mdiEyeOutline} size={0.8} />
                    </div>
                }
            </div>
        </Labelled>
    )
}
