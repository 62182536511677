import {apiConfig} from "./apiConfig";
import axios from "axios";
import {IApiLiveData} from "../typings/IApiLiveData";

const liveAxiosApi = axios.create({
    baseURL: apiConfig.liveApiUrl
});

export const LiveApi = {
    getAllData: async (): Promise<IApiLiveData[]> => {
        const res = await liveAxiosApi.get<IApiLiveData[]>("/api/live/all");
        return res.data;
    }
}
