import {useRef, useState} from "react";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {Spinner} from "../../components/base/Spinner/Spinner";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./UsersPage.module.scss";
import {useNavigate} from "react-router-dom";
import {usePrevious} from "../../utils/usePrevious";
import {Api} from "../../utils/api";
import {IUser} from "../../typings/IUser";
import {UserType} from "../../typings/UserType";
import moment from "moment";
import {formatSportType} from "../../typings/SportType";
import {Input} from "reactstrap";
import {useInfiniteQuery} from "@tanstack/react-query";

const pageSize = 60;

function userTypeToString(userType: UserType) {
    switch (userType) {
        case 1:
            return "Athlete";
        case 2:
            return "Coach";
        case 3:
            return "Admin";
    }
}

export const UsersPage = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState("");

    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['users', query],
        queryFn: ({pageParam}) => Api.getUsers(query, pageSize, pageParam * pageSize),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (!lastPage.hasMore) {
                return undefined
            }
            return lastPageParam + 1
        },
        getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
            if (firstPageParam <= 1) {
                return undefined
            }
            return firstPageParam - 1
        },
    })

    const handleOpenClick = (user: IUser) => {
        navigate(`/users/${user.id}`);
    };

    // To fix InfiniteScroll loads bad pages after filter change
    const usersPrev = usePrevious(data?.pages.flatMap(x => x.data));
    const scrollableDiv = useRef<HTMLDivElement | null>(null);
    const users = data?.pages.flatMap(x => x.data)
    if (usersPrev != null && users != null && usersPrev.length > users.length) {
        scrollableDiv.current?.scrollTo(0, 0);
    }

    let jj = 1;

    return (
        <DashboardLayout scrollable={false}>
            <SizedBox height={8}/>
            <div className={styles.header}>
                <div>
                    <div className={styles.nameText}>
                        Users
                    </div>
                    Filter
                    <Input style={{maxWidth: 300}} placeholder={"Id or name or email"} value={query}
                           onChange={(e) => setQuery(e.target.value)}/>
                </div>
            </div>

            <SizedBox height={16}/>
            <div id="scrollableDiv" className={styles.scrollParent} ref={scrollableDiv}>
                <InfiniteScroll
                    dataLength={users?.length ?? 0}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    loader={<div style={{display: "flex", alignItems: "center", justifyContent: "center", padding: 20}}>
                        <Spinner/>
                    </div>} // Loader component to show while loading
                    scrollableTarget="scrollableDiv"
                    style={{overflowY: "hidden"}}
                    endMessage={<div style={{
                        display: "flex", alignItems: "center", justifyContent: "center",
                        padding: 20, fontSize: 18
                    }}>
                        No More users
                    </div>}
                >
                    <div className={styles.grid}>
                        <div className={styles.row}>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>Id
                            </div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}></div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>Name
                            </div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>Sport
                            </div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>Club
                            </div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>User type
                            </div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>CloudId
                            </div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>Email
                            </div>
                            <div className={styles.cellHeader}
                                 style={{gridColumn: jj++, gridRow: 1}}>Created at
                            </div>
                        </div>
                        {users && users.map((x, i) => {
                                const row = i + 2;
                                let j = 1;
                                return <div key={x.id} className={styles.row}>
                                    <div style={{gridColumn: "1 / -1", gridRow: row}} className={styles.rowBg}/>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{x.id}</div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>
                                        <img style={{
                                            width: "32px",
                                            height: "32px",
                                            objectFit: "contain"
                                        }} src={x.profilePictureUrl}/>
                                    </div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{x.name}</div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{formatSportType(x.sport)}</div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{x.club}</div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{userTypeToString(x.userType)}</div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{x.cloudId}</div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{x.email}</div>
                                    <div
                                        style={{gridColumn: j++, gridRow: row}}
                                        onClick={() => handleOpenClick(x)}
                                        className={styles.cell}>{moment(x.createdAt).format("L")}</div>
                                </div>;
                            }
                        )}
                    </div>
                </InfiniteScroll>
            </div>
        </DashboardLayout>
    );
};
