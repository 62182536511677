//import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {AppWrapper} from './AppWrapper';
import './scss/global.css';
import './i18n';
import reportWebVitals from './reportWebVitals';
import './scss/_theme.scss';
import './scss/_bootstrap.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <StrictMode>
        <BrowserRouter>
            <AppWrapper/>
        </BrowserRouter>
    // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
