import moment from 'moment';
import {useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TimeInterval} from '../../../typings/TimeInterval';
import {Dropdown} from '../Dropdown/Dropdown';
import {DropdownLabel} from '../DropdownLabel/DropdownLabel';
import {SelectItem} from '../SelectItem/SelectItem';
import {SizedBox} from '../SizedBox/SizedBox';
import {Spacer} from '../Spacer/Spacer';
import styles from './TimeSelector.module.css';
import StatisticsContext from "../../../contexts/StatisticsContext";
import Icon from "@mdi/react";
import {mdiArrowLeft, mdiArrowRight} from "@mdi/js";

const defaultMinYear = 2020;
const defaultMaxYear = moment().year();

export const TimeSelector = () => {
    const {t} = useTranslation();
    const {date, timeInterval, setDate, setTimeInterval} = useContext(StatisticsContext);
    const weekSelector = useRef<HTMLDivElement>(null);
    const monthSelector = useRef<HTMLDivElement>(null);
    const yearSelector = useRef<HTMLDivElement>(null);
    const [weekSelectorOpen, setWeekSelectorOpen] = useState<boolean>(false);
    const [monthSelectorOpen, setMonthSelectorOpen] = useState<boolean>(false);
    const [yearSelectorOpen, setYearSelectorOpen] = useState<boolean>(false);
    const intervalSelector = useRef<HTMLDivElement>(null);
    const [intervalSelectorOpen, setIntervalSelectorOpen] = useState<boolean>(false);

    const years = useRef<number[]>(Array.from({length: defaultMaxYear - defaultMinYear + 1}, (_, i) => i + defaultMinYear).reverse());

    const decrementTime = () => {
        const newDate = moment(date).subtract(1, timeInterval).toDate();
        setDate(newDate);
    }

    const incrementTime = () => {
        const newDate = moment(date).add(1, timeInterval).toDate();
        setDate(newDate);
    }

    const getTimeString = () => {
        switch (timeInterval) {
            case TimeInterval.Month:
                return moment(date).format('MMMM');
            case TimeInterval.Week:
                return t('week x starting on y', {
                    week: moment(date).format('WW'),
                    start: moment(date).startOf('isoWeek').format('MMM D')
                });
            default:
                return '';
        }
    }

    const getIntervalString = (interval: TimeInterval) => {
        switch (interval) {
            case TimeInterval.Week:
                return t('weekly report');
            case TimeInterval.Month:
                return t('monthly report');
            default:
                return t('yearly report');
        }
    }

    const hasFuture = moment(date).endOf(timeInterval === TimeInterval.Week ? 'isoWeek' : timeInterval).isAfter(moment());

    return (
        <div className={styles.container}>
            <div className={styles.interval}>
                <div onClick={decrementTime} className={styles.timeSelectorButton}>
                    <Icon path={mdiArrowLeft} size={1} />
                </div>
                <div
                    onClick={incrementTime}
                    className={styles.timeSelectorButton}
                    style={hasFuture ? {
                        pointerEvents: 'none',
                        opacity: 0.5,
                    } : undefined}
                >
                    <Icon path={mdiArrowRight} size={1} />
                </div>
                <SizedBox width={4}/>
                {timeInterval === TimeInterval.Week &&
                    <div
                        ref={weekSelector}
                        onClick={() => setWeekSelectorOpen(!weekSelectorOpen)}
                        className={styles.timeString}
                    >
                        <DropdownLabel
                            label={getTimeString()}
                            open={weekSelectorOpen}
                        />
                        {weekSelectorOpen &&
                            <Dropdown anchor={weekSelector} setOpen={setWeekSelectorOpen}>
                                {Array.from(
                                    {length: moment(date).weeksInYear()},
                                    (_, i) => ({index: i + 1, m: moment(date).isoWeek(i + 1).startOf('isoWeek')})
                                ).filter(week => week.m.isBefore(moment()))
                                    .map(week => (
                                            <SelectItem
                                                key={week.index}
                                                label={t('week x', {week: week.index})}
                                                onClick={() => {
                                                    const newDate = week.m.toDate();
                                                    setDate(newDate);
                                                }}
                                            />
                                        )
                                    )}
                            </Dropdown>
                        }
                    </div>
                }
                {timeInterval === TimeInterval.Month &&
                    <div
                        ref={monthSelector}
                        onClick={() => setMonthSelectorOpen(!monthSelectorOpen)}
                        className={styles.timeString}
                    >
                        <DropdownLabel
                            label={getTimeString()}
                            open={monthSelectorOpen}
                        />
                        {monthSelectorOpen &&
                            <Dropdown anchor={monthSelector} setOpen={setMonthSelectorOpen}>
                                {Array.from(
                                    {length: 12},
                                    (_, i) => ({index: i, m: moment(date).month(i).startOf('month')})
                                ).filter(month => month.m.isBefore(moment()))
                                    .map(month => (
                                        <SelectItem
                                            key={month.index}
                                            label={month.m.format('MMMM')}
                                            onClick={() => {
                                                let newMoment = moment(month.m).add(10, 'days');
                                                while (newMoment.isAfter(moment())) {
                                                    newMoment = newMoment.subtract(1, 'day');
                                                }
                                                const newDate = newMoment.toDate();
                                                setDate(newDate);
                                            }}
                                        />
                                    ))}
                            </Dropdown>
                        }
                    </div>
                }
                <SizedBox width={8}/>
                <div
                    ref={yearSelector}
                    onClick={() => setYearSelectorOpen(!yearSelectorOpen)}
                    className={styles.selector}
                >
                    <DropdownLabel
                        label={moment(date).format('YYYY')}
                        open={yearSelectorOpen}
                    />
                    {yearSelectorOpen &&
                        <Dropdown anchor={yearSelector} setOpen={setYearSelectorOpen}>
                            {years.current.map(year => (
                                <SelectItem
                                    key={`year-${year}`}
                                    label={year.toString()}
                                    onClick={() => {
                                        let newDate = moment();
                                        if (timeInterval === TimeInterval.Week) {
                                            const week = moment(date).isoWeek();
                                            newDate = moment(date).year(year).isoWeek(week);
                                        } else if (timeInterval === TimeInterval.Month) {
                                            const month = moment(date).month();
                                            newDate = moment(date).year(year).month(month);
                                        } else {
                                            newDate = moment(date).year(year);
                                        }
                                        if (newDate.isAfter(moment())) {
                                            setDate(moment().startOf('day').toDate());
                                        } else {
                                            setDate(newDate.toDate());
                                        }
                                    }}
                                />
                            ))}
                        </Dropdown>
                    }
                </div>
            </div>
            <Spacer/>
            <div
                ref={intervalSelector}
                onClick={() => setIntervalSelectorOpen(!intervalSelectorOpen)}
                className={styles.selector}
            >
                <DropdownLabel
                    label={getIntervalString(timeInterval)}
                    open={intervalSelectorOpen}
                />
                {intervalSelectorOpen &&
                    <Dropdown anchor={intervalSelector} setOpen={setIntervalSelectorOpen}>
                        <SelectItem
                            label={getIntervalString(TimeInterval.Week)}
                            onClick={() => setTimeInterval(TimeInterval.Week)}
                        />
                        <SelectItem
                            label={getIntervalString(TimeInterval.Month)}
                            onClick={() => setTimeInterval(TimeInterval.Month)}
                        />
                        <SelectItem
                            label={getIntervalString(TimeInterval.Year)}
                            onClick={() => setTimeInterval(TimeInterval.Year)}
                        />
                    </Dropdown>
                }
            </div>
        </div>
    );
};
