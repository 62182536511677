import Highcharts from "highcharts";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import {Navigate, Route, Routes} from 'react-router-dom';
import {DialogContainer} from './components/base/DialogContainer/DialogContainer';
import AuthRoute from './components/routes/AuthRoute/AuthRoute';
import NonAuthRoute from './components/routes/NonAuthRoute/NonAuthRoute';
import {TrainingsContextProvider} from './contexts/TrainingsContext';
import {AnalyticsPage} from './pages/AnalyticsPage/AnalyticsPage';
import {CoachesPage} from "./pages/CoachesPage/CoachesPage";
import {DashboardPage} from './pages/DashboardPage/DashboardPage';
import {ForgottenPasswordPage} from './pages/ForgottenPasswordPage/ForgottenPasswordPage';
import LoginPage from './pages/LoginPage/LoginPage';
import {ProfilePage} from "./pages/ProfilePage/ProfilePage";
import {ResetPasswordPage} from "./pages/ResetPasswordPage/ResetPasswordPage";
import {TrainingsPage} from './pages/TrainingsPage/TrainingsPage';
import {UsersPage} from "./pages/UsersPage/UsersPage";
import {UserDetailsPage} from "./pages/UserDetailsPage/UserDetailsPage";
import {useAxiosInterceptor} from "./utils/axios-interceptors";
import {LivePage} from "./pages/LivePage/LivePage";

HighchartsAccessibility(Highcharts);

// Highcharts point highlight function extension
// See https://www.highcharts.com/demo/highcharts/synchronized-charts

(Highcharts.Point.prototype as any).drawCrosshair = function (event: any) {
    // event = this.series.chart.pointer.normalize(event);
    this.series.chart.tooltip.refresh(this); // Show the tooltip
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
};

const MaybeReset = () => {
    const searchString = window.location.search;
    if (!searchString || !searchString.length || searchString[0] !== "?") {
        return <Navigate to="/login" replace/>;
    }
    const hashParts = searchString.split("hash=");
    if (!hashParts || !hashParts.length || hashParts.length === 1) {
        return <Navigate to="/login" replace/>;
    }
    const hash = hashParts[1].split("?")[0];
    if (!hash || !hash.length) {
        return <Navigate to="/login" replace/>;
    }
    return <Navigate to={`/reset-password?hash=${hash}`} replace/>;
}

export const App = () => {
    useAxiosInterceptor();
    return (
        <>
            <Routes>
                <Route path="/" element={<MaybeReset/>}/>
                <Route element={<NonAuthRoute/>}>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/forgotten-password" element={<ForgottenPasswordPage/>}/>
                    <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                </Route>
                <Route element={<AuthRoute/>}>
                    <Route path="/dashboard" element={<DashboardPage/>}/>
                    <Route path="/trainings" element={
                        <TrainingsContextProvider><TrainingsPage/></TrainingsContextProvider>}/>
                    <Route path="/coaches" element={<CoachesPage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/trainings/:trainingId"
                           element={<TrainingsContextProvider><AnalyticsPage/></TrainingsContextProvider>}/>
                    <Route path="/users" element={<UsersPage/>}/>
                    <Route path="/users/:userId" element={<UserDetailsPage/>}/>
                    <Route path="/live" element={<LivePage/>}/>
                </Route>
            </Routes>
            <DialogContainer/>
        </>
    );
}
