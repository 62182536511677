import Highcharts, {Chart} from "highcharts";
import HighchartsReact, {HighchartsReactRefObject} from "highcharts-react-official";
import {forwardRef, memo, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import ResponsiveContext, {ScreenType} from "../../../../contexts/ResponsiveContext";
import {IZone} from "../../../../typings/IZone";
import {IZoneData} from "../../../../typings/IZoneData";
import {SeriesType} from "../../../../typings/SeriesType";
import {ChartHelper} from "../../../../utils/chartHelper";
import styles from "./StandardGraphs.module.css";
import moment from "moment/moment";
import {useDoubleTap} from "../../../../hooks/useDoubleTap";
import {isTwoPaddle, TrainingSport} from "../../../../typings/TrainingSport";
import {mdiFullscreen} from "@mdi/js";
import Icon from "@mdi/react";
import {Button} from "reactstrap";

interface IStandardGraphs {
    heartRateSeries: (number[][] | null)[];
    heartRateMax: number,
    speedSeries: number[][] | null;
    speedMax: number;
    tempoSeries: number[][] | null;
    tempoMax: number,
    tempo500Series: number[][] | null;
    tempo500Max: number,
    strokeSeries: number[][] | null;
    strokeMax: number,
    distancePerStrokeSeries: number[][] | null;
    distancePerStrokeMax: number;
    forceLeftSeries: (number[][] | null)[];
    forceRightSeries: (number[][] | null)[];
    podBatteryLeftSeries: (number[][] | null)[];
    podBatteryRightSeries: (number[][] | null)[];
    forceMax: number,
    distanceSeries: number[][] | null;
    hrZonesData?: IZoneData[];
    speedZonesData?: IZoneData[];
    tempoZonesData?: IZoneData[];
    tempo500ZonesData?: IZoneData[];
    forceZonesData?: IZoneData[];
    band: [number, number] | null;
    detailedBand: [number, number] | null;
    charts: SeriesType[];
    sliceStart: number;
    sliceEnd: number;
    hrZones?: IZone[];
    sport: TrainingSport;
    hasZones: boolean;
    isTracker: boolean;
    max: number;
    cursor: MutableRefObject<number>;
    onSelectionChange: (selection: [number, number]) => void;
    onZoomChange: (zoom: [number, number]) => void;
    onCursorChange: (cursor: number) => void;
    onFullscreenClick: (type: SeriesType) => void;
}

export const forceSeriesColors = [
    "#3AB3E5", "#e34a4a",
    "#51e53a", "#dcd800",
    "#ff7300", "#d954e8",
    "#f80f78", "#858585",
    "#005173", "#135d00"
]

const chartHeight = 160;
export const zoneWidth = 200;

const standardOptions = ChartHelper.getStandardChartOptions(chartHeight);

const zoneChartOptions = ChartHelper.getZoneChartOptions(chartHeight);

const LineChart = memo(forwardRef<
    HighchartsReactRefObject,
    any
>(({
       tooltipFormatter, unit, showXLabels, xAxisLabelFormatter,
       plotBands, setExtremes, yAxisTitle, options, series, hideFullscreen, onFullscreenClick
   }: any, propRef) => {
    const ref = useRef();
    const setRef = useCallback((el: any) => {
        ref.current = el;
        (propRef as (instance: HighchartsReactRefObject | null) => void)(el);
    }, [propRef]);
    return <div className={"position-relative"}>
        <HighchartsReact
            ref={setRef}
            callback={(chart: Chart) => {
                // Override the reset function, we don't need to hide the tooltips and crosshairs.
                // They are shown for every chart synchronized
                chart.pointer.reset = () => {
                };
            }}
            highcharts={Highcharts}
            options={{
                ...standardOptions,
                tooltip: {
                    ...standardOptions.tooltip,
                    formatter: tooltipFormatter(unit),
                    ...(options?.tooltip),
                },
                xAxis: {
                    ...standardOptions.xAxis,
                    labels: {
                        enabled: showXLabels,
                        useHTML: true,
                        formatter: xAxisLabelFormatter,
                    },

                    plotBands,
                    events: {
                        setExtremes
                    },
                },
                yAxis: {
                    ...standardOptions.yAxis,
                    title: {
                        text: yAxisTitle,
                    },
                    ...(options?.yAxis)
                },
                series: series.some((x: any) => x.data != null) ? series : [{
                    data: series,
                }],
                chart: {
                    ...standardOptions.chart,
                    height: standardOptions.chart.height + (showXLabels ? 50 : 0),
                    ...(options?.chart)
                }
            }}
            updateArgs={[true, true, false]}
        />
        {!hideFullscreen && <div className={styles.topRight}>
            <Button size="sm" outline={true} className={styles.fullscreenButton} onClick={onFullscreenClick}>
                <Icon path={mdiFullscreen}
                      size={0.8}/>
            </Button>
        </div>}
    </div>;
}));

export const tempoFormatter = (x: number) => Highcharts.dateFormat("%M:%S", x * 60 * 1000);

const forceFormatter = (x: number) => x.toFixed(0).replace('-0', '0');

export const StandardGraphs = ({
                                   heartRateSeries,
                                   heartRateMax,
                                   speedSeries,
                                   speedMax,
                                   tempoSeries,
                                   tempoMax,
                                   tempo500Series,
                                   tempo500Max,
                                   strokeSeries,
                                   strokeMax,
                                   distancePerStrokeSeries,
                                   distancePerStrokeMax,
                                   forceLeftSeries,
                                   forceRightSeries,
                                   podBatteryLeftSeries,
                                   podBatteryRightSeries,
                                   forceMax,
                                   distanceSeries,
                                   hrZones,
                                   band,
                                   detailedBand,
                                   charts,
                                   cursor,
                                   onSelectionChange,
                                   onZoomChange,
                                   sliceStart,
                                   sliceEnd,
                                   hrZonesData,
                                   speedZonesData,
                                   tempoZonesData,
                                   tempo500ZonesData,
                                   forceZonesData,
                                   sport,
                                   max,
                                   hasZones,
                                   isTracker,
                                   onCursorChange,
                                   onFullscreenClick
                               }: IStandardGraphs) => {
    const {t} = useTranslation();
    const chartRefs = useRef<any[]>([]);
    const zoneChartRefs = useRef<any[]>([]);
    const {windowSize, screenType} = useContext(ResponsiveContext);

    const mouseMove = useCallback((e: PointerEvent | MouseEvent | TouchEvent) => {
        // See: https://www.highcharts.com/demo/highcharts/synchronized-charts
        // and https://www.highcharts.com/forum/viewtopic.php?t=33438
        let charts: Chart[] = chartRefs.current
            .filter(chart => chart !== null && chart !== undefined).map(c => c.chart as Chart);
        for (let i = 0; i < charts.length; i += 1) {
            try {
                const chart = charts[i];
                // if this the one we are targeting?
                const container = (e.target as any)?.closest(".highcharts-container");
                if (container === chart.container && chart.hoverPoint != null) {
                    onCursorChange(chart.hoverPoint.x);
                }
            } catch (e) {
            }
        }
    }, [onCursorChange]);

    useEffect(() => {
        const t = window.setInterval(() => {
            let charts: Chart[] = chartRefs.current
                .filter(chart => chart !== null && chart !== undefined).map(c => c.chart as Chart);
            for (let i = 0; i < charts.length; i += 1) {
                try {
                    const lastPoint = charts[i].series[0].points[charts[i].series[0].points.length - 1];
                    if (lastPoint === undefined)
                        continue
                    const number = Math.round((charts[i].series[0].data.length - 1) * cursor.current / lastPoint.x);
                    // @ts-ignore
                    charts[i].series[0].data[number].drawCrosshair(null);
                } catch (e) {
                    console.log(e)
                }
            }
        }, 200);
        return () => clearInterval(t);
    }, [cursor]);

    useEffect(() => {
        let charts: Chart[] = chartRefs.current
            .filter(chart => chart !== null && chart !== undefined).map(c => c.chart as Chart);
        for (const chart of charts) {
            if (chart.xAxis?.[0]) {
                chart.xAxis[0].setExtremes(
                    sliceStart,
                    sliceEnd,
                    undefined,
                    false,
                    {trigger: "syncExtremes"}
                );
                // if (chart && (chart as any).resetZoomButton && (chart as any).resetZoomButton.destroy) {
                //     (chart as any).resetZoomButton.hide();
                // }
            }
        }
    }, [sliceStart, sliceEnd]);


    const setExtremes = useCallback((e: any) => { // See: https://www.highcharts.com/demo/highcharts/synchronized-charts
        if (e.trigger === "syncExtremes") return;
        // let charts: Chart[] = chartRefs.current
        //     .filter(chart => chart !== null && chart !== undefined).map(c => c.chart as Chart);
        // for (let i = 0; i < charts.length; i += 1) {
        //     const chart = charts[i];
        //     chart.xAxis[0].setExtremes(
        //         e.min,
        //         e.max,
        //         undefined,
        //         false,
        //         {trigger: "syncExtremes"}
        //     );
        // }
        if (e.min != null && e.max != null) {
            onSelectionChange([e.min, e.min === e.max ? e.max + 1000 : e.max]);
        }
    }, [onSelectionChange]);

    const onResetZoom = useCallback((e: any | null) => {
        e?.preventDefault();
        e?.stopPropagation();
        onSelectionChange([0, max]);
        let charts: Chart[] = chartRefs.current.filter(chart => chart !== null && chart !== undefined)
            .map(c => c.chart as Chart);
        for (let chart of charts) {
            //chart.xAxis[0].setExtremes();
            chart.zoomOut();
        }
    }, [onSelectionChange, max]);

    const tooltipFormatterFactory = useCallback(
        (valueFormatter?: ((x: number) => string), timeFormat?: string) => {
            valueFormatter ??= (x) => x.toFixed(1).replace('-0', '0');
            timeFormat ??= "H:mm:ss";
            return (unitText: string) => {
                return function (this: any, tooltip: any) {
                    let firstColumn = String(moment(this.x).utc().format(timeFormat));
                    if (distanceSeries) {
                        firstColumn += "<br />";
                        const distance = distanceSeries[Math.floor(this.x / (isTracker ? 100 : 1000))][1] || 0;
                        firstColumn += Highcharts.numberFormat(distance, 1) + " km";
                    }
                    const secondColumn = this.points.map((point: any) =>
                        `<div style="color: ${point.color}; font-weight: 700">
                            ${valueFormatter!(point.y)} ${unitText}
                            </div>`).join("");
                    return `<div style="display: flex; align-items: center">
                            <div>${firstColumn}</div>
                            <div style="margin-left: 6px">${secondColumn}</div>
                            </div>`;
                };
            };
        },
        [distanceSeries, isTracker],
    );

    const xAxisLabelFormatter = useCallback(
        (self: any) => {
            let s;
            if (self.dateTimeLabelFormat)
                s = Highcharts.dateFormat(self.dateTimeLabelFormat, self.value);
            else
                s = "0";
            if (distanceSeries) {
                s += "<br />";
                const dp = distanceSeries[Math.floor(self.value / 1000)];
                const distance = dp?.[1] || 0;
                s += Highcharts.numberFormat(distance, 1) + " km";
            }
            return `<div style="text-align: center">${s}</div>`;
        },
        [distanceSeries],
    );

    const containerRef = useRef<HTMLDivElement>();

    const wheelListener = useCallback(
        (event: WheelEvent) => {
            if (sliceStart === 0 && sliceEnd === max)
                return;
            event.preventDefault();
            event.stopPropagation();
            const start = sliceStart;
            const end = sliceEnd;
            // scale "1" scroll = 10% of the slice
            const tenPct = (end - start) / 10;
            let d = event.deltaY / 100 * tenPct;
            //clamp step
            if (start + d < 0) {
                d = 0 - start;
            }
            if (end + d > max) {
                d = max - end;
            }
            onZoomChange([start + d, end + d])
        },
        [sliceStart, sliceEnd, max, onZoomChange],
    );

    const handleContainerRef = useCallback((element: HTMLDivElement) => {
        if (element) {
            containerRef.current = element;
            element.addEventListener("wheel", wheelListener, {passive: false});
        } else if (containerRef.current) {
            containerRef.current.removeEventListener("wheel", wheelListener);
            containerRef.current = undefined;
        }
    }, [wheelListener]);

    const setChartRef = (i: number) => (el: any) => chartRefs.current[i] = el;
    const setZoneChartRef = (i: number) => (el: any) => zoneChartRefs.current[i] = el;

    useEffect(() => {
        // highcharts need to be resized manually
        for (const key in chartRefs.current) {
            chartRefs.current[key]?.chart.reflow();
        }
    }, [windowSize]);

    useEffect(() => {
        // highcharts need to be resized manually
        for (const key in zoneChartRefs.current) {
            zoneChartRefs.current[key]?.chart.reflow();
        }
    }, [windowSize]);

    const {onClick} = useDoubleTap(() => {
        onResetZoom(null);
    });

    let chartRefIndex = 0;
    let zoneChartRefIndex = 0;
    const mobileOrTablet = [ScreenType.mobile, ScreenType.tablet].includes(screenType);
    const forceSeries = useMemo(() =>
            forceLeftSeries.map((_, i) => [
                {
                    data: forceLeftSeries[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1)) % forceSeriesColors.length],
                    dataGrouping: {
                        approximation: "max"
                    }
                },
                {
                    data: forceRightSeries[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1) + 1) % forceSeriesColors.length],
                    dataGrouping: {
                        approximation: "max"
                    }
                }
            ]).flat().filter(x => x.data),
        [forceLeftSeries, forceRightSeries, sport]);
    const podBatterySeries = useMemo(() =>
            podBatteryLeftSeries.map((_, i) => [
                {
                    data: podBatteryLeftSeries[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1)) % forceSeriesColors.length],
                    dataGrouping: {
                        approximation: "max"
                    }
                },
                {
                    data: podBatteryRightSeries[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1) + 1) % forceSeriesColors.length],
                    dataGrouping: {
                        approximation: "max"
                    }
                }
            ]).flat().filter(x => x.data),
        [podBatteryLeftSeries, podBatteryRightSeries, sport]);

    const bands = useMemo(() => {
        const res = [];
        if (band)
            res.push({
                from: band[0],
                to: band[1],
                color: "rgba(140,140,140,0.07)",
            });
        if (detailedBand)
            res.push({
                from: detailedBand[0],
                to: detailedBand[1],
                color: "rgba(58, 179, 229,0.07)"
            });
        return res;
    }, [band, detailedBand]);

    return (
        <div
            className={styles.container}
        >
            <div style={{flex: 1, display: "flex", flexDirection: "column", minWidth: 0}}>
                <div
                    onMouseMove={mouseMove as any}
                    ref={handleContainerRef}
                    onClick={onClick}
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    {heartRateSeries && charts.includes(SeriesType.HEARTRATE) &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory()} unit="bpm"
                                   showXLabels={!(charts.includes(SeriesType.SPEEDS) ||
                                       charts.includes(SeriesType.TEMPOS) || charts.includes(SeriesType.STROKES) ||
                                       charts.includes(SeriesType.PULLING_FORCE) || charts.includes(SeriesType.TEMPO_500S) ||
                                       charts.includes(SeriesType.DISTANCEPERSTROKE))}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("heartrate")}
                                   onFullscreenClick={() => onFullscreenClick(SeriesType.HEARTRATE)}
                                   options={{
                                       yAxis: {
                                           plotBands: charts.includes(SeriesType.HEARTRATEPLOTBANDS) ? hrZones?.map(z => ({
                                               from: z.min,
                                               to: z.max,
                                               color: z.color,
                                           })) : [],
                                           min: 0,
                                           max: heartRateMax
                                       }
                                   }}
                                   series={heartRateSeries
                                       .map((x, i) => (
                                           {
                                               data: x,
                                               color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1)) % forceSeriesColors.length],
                                               dataGrouping: {
                                                   approximation: "max"
                                               }
                                           }
                                       )).filter(x => !!x.data)}
                        />
                    }
                    {speedSeries && charts.includes(SeriesType.SPEEDS) &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory(undefined,
                                       isTracker ? "H:mm:ss.S" : undefined)}
                                   unit="km/h"
                                   showXLabels={!(charts.includes(SeriesType.TEMPOS) || charts.includes(SeriesType.STROKES) ||
                                       charts.includes(SeriesType.PULLING_FORCE) || charts.includes(SeriesType.TEMPO_500S) ||
                                       charts.includes(SeriesType.DISTANCEPERSTROKE))}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("avg speed")}
                                   series={speedSeries}
                                   onFullscreenClick={() => onFullscreenClick(SeriesType.SPEEDS)}
                                   options={{
                                       yAxis: {
                                           min: 0,
                                           max: speedMax
                                       }
                                   }}/>
                    }
                    {tempoSeries && charts.includes(SeriesType.TEMPOS) &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory(tempoFormatter,
                                       isTracker ? "H:mm:ss.S" : undefined)}
                                   unit="/km"
                                   showXLabels={!(charts.includes(SeriesType.STROKES) ||
                                       charts.includes(SeriesType.PULLING_FORCE) ||
                                       charts.includes(SeriesType.TEMPO_500S) ||
                                       charts.includes(SeriesType.DISTANCEPERSTROKE))}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("avg tempo")}
                                   series={tempoSeries}
                                   onFullscreenClick={() => onFullscreenClick(SeriesType.TEMPOS)}
                                   options={{
                                       yAxis: {
                                           startOnTick: false,
                                           endOnTick: false,
                                           reversed: true,
                                           tickInterval: tempoMax / 3,
                                           labels: {
                                               formatter: function (self: any) {
                                                   return Highcharts.dateFormat("%M:%S", self.value * 60 * 1000);
                                               },
                                               align: "center"
                                           },
                                           min: tempoMax,
                                           max: 15
                                       }
                                   }}/>
                    }
                    {tempo500Series && charts.includes(SeriesType.TEMPO_500S) &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory(tempoFormatter,
                                       isTracker ? "H:mm:ss.S" : undefined)}
                                   unit="/500m"
                                   showXLabels={!(charts.includes(SeriesType.STROKES) ||
                                       charts.includes(SeriesType.PULLING_FORCE) ||
                                       charts.includes(SeriesType.DISTANCEPERSTROKE))}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("avg tempo split")}
                                   series={tempo500Series}
                                   onFullscreenClick={() => onFullscreenClick(SeriesType.TEMPO_500S)}
                                   options={{
                                       yAxis: {
                                           startOnTick: false,
                                           endOnTick: false,
                                           reversed: true,
                                           tickInterval: tempo500Max / 3,
                                           labels: {
                                               formatter: function (self: any) {
                                                   return Highcharts.dateFormat("%M:%S", self.value * 60 * 1000);
                                               },
                                               align: "center"
                                           },
                                           min: tempo500Max,
                                           max: 8
                                       }
                                   }}/>
                    }
                    {strokeSeries && charts.includes(SeriesType.STROKES) &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory()}
                                   unit="s/min"
                                   showXLabels={!(charts.includes(SeriesType.PULLING_FORCE) ||
                                       charts.includes(SeriesType.DISTANCEPERSTROKE))}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("avg stroke rate")}
                                   series={strokeSeries}
                                   onFullscreenClick={() => onFullscreenClick(SeriesType.STROKES)}
                                   options={{
                                       yAxis: {
                                           min: 0,
                                           max: strokeMax
                                       }
                                   }}/>
                    }
                    {distancePerStrokeSeries && charts.includes(SeriesType.DISTANCEPERSTROKE) &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory((x: number) => x.toFixed(3).replace('-0', '0'))}
                                   unit={"m"}
                                   showXLabels={!charts.includes(SeriesType.PULLING_FORCE)}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("distance per stroke")}
                                   series={distancePerStrokeSeries}
                                   onFullscreenClick={() => onFullscreenClick(SeriesType.DISTANCEPERSTROKE)}
                                   options={{
                                       yAxis: {
                                           min: 0,
                                           max: distancePerStrokeMax
                                       }
                                   }}/>
                    }
                    {forceSeries && charts.includes(SeriesType.PULLING_FORCE) &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory(forceFormatter)}
                                   unit="N"
                                   showXLabels={true}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("avg pulling force")}
                                   series={forceSeries}
                                   onFullscreenClick={() => onFullscreenClick(SeriesType.PULLING_FORCE)}
                                   options={{
                                       yAxis: {
                                           min: 0,
                                           max: forceMax
                                       }
                                   }}/>
                    }
                    {podBatterySeries.length > 0 &&
                        <LineChart ref={setChartRef(chartRefIndex++)}
                                   tooltipFormatter={tooltipFormatterFactory(forceFormatter)}
                                   unit="%"
                                   showXLabels={true}
                                   xAxisLabelFormatter={xAxisLabelFormatter}
                                   plotBands={bands}
                                   setExtremes={setExtremes}
                                   yAxisTitle={t("pod battery")}
                                   series={podBatterySeries}
                                   hideFullscreen={true}/>
                    }
                </div>
                {/*<div>*/}
                {/*    {charts.find(x => [SeriesType.HEARTRATE,*/}
                {/*            SeriesType.SPEEDS, SeriesType.TEMPOS, SeriesType.TEMPO_500S, SeriesType.PULLING_FORCE,*/}
                {/*            SeriesType.STROKES].includes(x)) &&*/}
                {/*        !mobileOrTablet && <ChartStepper*/}
                {/*            style={{marginLeft: 80, marginBottom: 20}}*/}
                {/*            min={0}*/}
                {/*            max={max}*/}
                {/*            from={sliceStart}*/}
                {/*            to={sliceEnd}*/}
                {/*            onChange={(from, to) => {*/}
                {/*                if (from < to)*/}
                {/*                    onZoomChange([from, to])*/}
                {/*            }}*/}
                {/*        />}*/}
                {/*</div>*/}
            </div>
            {hasZones &&
                <div className={styles.zonesContainer}>
                    {(heartRateSeries && charts.includes(SeriesType.HEARTRATE)) ?
                        (charts.includes(SeriesType.HEARTRATE_ZONES) && hrZonesData ?
                                <HighchartsReact
                                    ref={setZoneChartRef(zoneChartRefIndex++)}
                                    highcharts={Highcharts}
                                    updateArgs={[true, true, false]}
                                    options={{
                                        ...zoneChartOptions,
                                        xAxis: {
                                            categories: hrZonesData?.map(z => z.name),
                                            ...(mobileOrTablet && {
                                                title: {
                                                    text: t("heartrate"),
                                                }
                                            }),
                                        },
                                        series: [{
                                            data: hrZonesData?.map(z => ({y: z.value, color: z.color})),
                                        }],
                                        tooltip: {
                                            useHTML: true,
                                            formatter: function (): string {
                                                const self = this as any;
                                                const x = self.x;
                                                const zone = hrZonesData?.find(z => z.name === x);
                                                return `<b>${(self.y as number).toFixed(0).replace('-0', '0')}%</b> (${zone?.tooltip})`;
                                            },
                                        },
                                    }}
                                /> : (!mobileOrTablet ? <div style={{width: zoneWidth, height: chartHeight}}/> : null)
                        ) : null
                    }
                    {(speedSeries && charts.includes(SeriesType.SPEEDS)) ?
                        (charts.includes(SeriesType.SPEED_ZONES) && speedZonesData ?
                                <HighchartsReact
                                    ref={setZoneChartRef(zoneChartRefIndex++)}
                                    highcharts={Highcharts}
                                    updateArgs={[true, true, false]}
                                    options={{
                                        ...zoneChartOptions,
                                        xAxis: {
                                            categories: speedZonesData?.map(z => z.name),
                                            ...(mobileOrTablet && {
                                                title: {
                                                    text: t("speed"),
                                                }
                                            }),
                                        },
                                        series: [{
                                            data: speedZonesData?.map(z => ({y: z.value, color: z.color})),
                                        }],
                                        tooltip: {
                                            useHTML: true,
                                            formatter: function (): string {
                                                const self = this as any;
                                                const x = self.x;
                                                const zone = speedZonesData?.find(z => z.name === x);
                                                return `<b>${(self.y as number).toFixed(1).replace('-0', '0')}%</b> (${zone?.tooltip})`;
                                            },
                                        },
                                    }}
                                /> : (!mobileOrTablet ? <div style={{width: zoneWidth, height: chartHeight}}/> : null)
                        ) : null
                    }
                    {(tempoSeries && charts.includes(SeriesType.TEMPOS)) ?
                        (charts.includes(SeriesType.TEMPO_ZONES) && tempoZonesData ?
                                <HighchartsReact
                                    ref={setZoneChartRef(zoneChartRefIndex++)}
                                    highcharts={Highcharts}
                                    updateArgs={[true, true, false]}
                                    options={{
                                        ...zoneChartOptions,
                                        xAxis: {
                                            categories: tempoZonesData?.map(z => z.name),
                                            ...(mobileOrTablet && {
                                                title: {
                                                    text: t("tempo"),
                                                }
                                            }),
                                        },
                                        series: [{
                                            data: tempoZonesData?.map(z => ({y: z.value, color: z.color})),
                                        }],
                                        tooltip: {
                                            useHTML: true,
                                            formatter: function (): string {
                                                const self = this as any;
                                                const x = self.x;
                                                const zone = tempoZonesData?.find(z => z.name === x);
                                                return `<b>${(self.y as number).toFixed(1).replace('-0', '0')}%</b> (${zone?.tooltip})`;
                                            },
                                        },
                                    }}
                                /> : (!mobileOrTablet ? <div style={{width: zoneWidth, height: chartHeight}}/> : null)
                        ) : null
                    }
                    {(tempo500Series && charts.includes(SeriesType.TEMPO_500S)) ?
                        (charts.includes(SeriesType.TEMPO_500_ZONES) && tempo500ZonesData ?
                                <HighchartsReact
                                    ref={setZoneChartRef(zoneChartRefIndex++)}
                                    highcharts={Highcharts}
                                    updateArgs={[true, true, false]}
                                    options={{
                                        ...zoneChartOptions,
                                        xAxis: {
                                            categories: tempo500ZonesData?.map(z => z.name),
                                            ...(mobileOrTablet && {
                                                title: {
                                                    text: t("tempo split"),
                                                }
                                            }),
                                        },
                                        series: [{
                                            data: tempo500ZonesData?.map(z => ({y: z.value, color: z.color})),
                                        }],
                                        tooltip: {
                                            useHTML: true,
                                            formatter: function (): string {
                                                const self = this as any;
                                                const x = self.x;
                                                const zone = tempo500ZonesData?.find(z => z.name === x);
                                                return `<b>${(self.y as number).toFixed(1).replace('-0', '0')}%</b> (${zone?.tooltip})`;
                                            },
                                        },
                                    }}
                                /> : (!mobileOrTablet ? <div style={{width: zoneWidth, height: chartHeight}}/> : null)
                        ) : null
                    }
                    {strokeSeries && charts.includes(SeriesType.STROKES) &&
                        <div style={{width: zoneWidth, height: chartHeight}} className={styles.hideInMobile}/>}
                    {distancePerStrokeSeries && charts.includes(SeriesType.DISTANCEPERSTROKE) &&
                        <div style={{width: zoneWidth, height: chartHeight}} className={styles.hideInMobile}/>}
                    {(forceSeries && charts.includes(SeriesType.PULLING_FORCE)) ?
                        (charts.includes(SeriesType.FORCE_ZONES) && forceZonesData ?
                            <HighchartsReact
                                ref={setZoneChartRef(zoneChartRefIndex++)}
                                highcharts={Highcharts}
                                updateArgs={[true, true, false]}
                                options={{
                                    ...zoneChartOptions,
                                    xAxis: {
                                        categories: forceZonesData?.map(z => z.name),
                                        ...(mobileOrTablet && {
                                            title: {
                                                text: t("force zones"),
                                            }
                                        }),
                                    },
                                    series: [{
                                        data: forceZonesData?.map(z => ({y: z.value, color: z.color})),
                                    }],
                                    yAxis: {
                                        ...zoneChartOptions.yAxis,
                                        max: undefined,
                                    },
                                    tooltip: {
                                        useHTML: true,
                                        formatter: function (): string {
                                            const self = this as any;
                                            const x = self.x;
                                            const zone = forceZonesData?.find(z => z.name === x);
                                            return `<b>${(self.y as number).toFixed(0).replace('-0', '0')}%</b> (${zone?.tooltip})`;
                                        },
                                    },
                                }}
                            /> : (!mobileOrTablet ?
                                <div style={{width: zoneWidth, height: chartHeight}}/> : null)) : null
                    }
                </div>
            }
        </div>
    );
};
